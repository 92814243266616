export const EventType = {
    'emergency-call': 'emergency-call',
    NOTIFICATION_TYPE_EMERGENCY_CALL: 'emergency-call',
    NOTIFICATION_TYPE_DOCTOR_CALL: 'doctor-call',
    NOTIFICATION_TYPE_PATIENT_CALL: 'patient-call',
    NOTIFICATION_TYPE_SPECIALIST_CALL: 'specialist-call',
    NOTIFICATION_TYPE_ADM_SERVICE_CALL: 'adm-service-call',
    NOTIFICATION_TYPE_NOT_MEDICATION: 'not-medication',
    NOTIFICATION_TYPE_REPORT_DEVICE: 'device-report',
    NOTIFICATION_TYPE_RECOMMENDATION: 'recommendation',
    NOTIFICATION_TYPE_PRESCRIPTION_DELETED: 'prescription-deleted',
    NOTIFICATION_TYPE_PRESCRIPTION_UPDATED: 'prescription-updated',
    NOTIFICATION_TYPE_PRESCRIPTION_CREATED: 'prescription-created',
    NOTIFICATION_TYPE_MEDICAL_PERSONAL_ASSIGNED: 'medical-personal-assigned',
    NOTIFICATION_TYPE_DEVICE_ASSIGNED: 'device-assigned',
    NOTIFICATION_TYPE_NOTE_DR_DAILY_EVOL: 'dr-notes-daily-evol',
    NOTIFICATION_TYPE_NOTE_PATIENT: 'patient-notes-general',
    NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_CODE_GENERATED: 'device-alexa-pairing-code-generated',
    NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_SUCCESSFUL: 'device-alexa-pairing-successful',
    NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_FAILED: 'device-alexa-pairing-failed',
    NOTIFICATION_TYPE_PATIENT_RELEASED: 'patient-released',
    NOTIFICATION_TYPE_SPECIALISTY_REQUESTS_CREATED: 'specialisty-requests-created',
    NOTIFICATION_TYPE_CONSULTATION_SPECIALISTY_REQUESTS: 'consultation-specialisty-requests',
    GROUP_NOTIFICATION_CALL: 'call',
    GROUP_NOTIFICATION_MEDICATION: 'medication',
    GROUP_NOTIFICATION_REPORT: 'report',
    GROUP_PRESCRIPTION: 'prescription',
    GROUP_RECOMMENDATION: 'recommendation',
    GROUP_CONSULTATION: 'consultation',
    GROUP_QUERY_WORKPLAN: 'queryWorkplan',
    GROUP_QUERY_ANNOTATIONS: 'annotations',
    GROUP_PATIENT_EVENTS: 'patient-events'
}
