import { onAlert } from "components/Alert";
import { useCreateGeneralNotification, useCreateNotificationCallPatient } from "features/ClinicHistory/hooks/useNotification";
import { useNotificationContext } from "features/Notifications/hooks";
import { NotificationSummary, NotificationType, useMarkNotificationAsRead } from "features/Notifications/services";
import useUser from "features/shared/hooks/useUser";
import { calculateActionRoutes } from "features/shared/utils/calculateActionRoutes";
import { calculateNotificationActionRoute } from "features/shared/utils/calculateNotificationActionRoutes";
import { AVAILABLE_ACTIONS } from "global/constants/enums";
import { EventType } from "global/constants/eventType";
import { ROLE } from "global/constants/roles";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "routes/routing";

export const useHandleNotificationAction = (notification: NotificationSummary) => {
  const { userCurrent } = useUser();
  const history = useHistory();
  const location = useLocation();

  const {
    setOpenVideoCall,
    setSelectedNotification,
    setOpenDeviceReport,
  } = useNotificationContext()

  const onErrorMarkAsRead = () => {
    setSelectedNotification(null)
  }

  const onSettledMarkAsRead = async () => {
    //TODO: Handle next action.
    const notificationType = notification?.notificationType;
    const notificationSubtype = notification.data?.body?.eventType || notification.data?.body?.type;
    switch (notificationType) {
      case NotificationType.call:
        const patientId = notification.data.body.patientId;
        const userId = userCurrent?.id;
        //Patient
        if (userCurrent?.roleSlug === ROLE.PATIENT) {
          if ([
            EventType.NOTIFICATION_TYPE_DOCTOR_CALL,
            EventType.NOTIFICATION_TYPE_SPECIALIST_CALL,
          ].includes(notificationSubtype || "")) {
            await notifyDoctorForPatientCall({
              patientId: `${patientId}`
            })
            setSelectedNotification(notification);
            setOpenVideoCall(true)
          }
        }
        //Health Professional
        if (userCurrent?.roleSlug === ROLE.HEALTH_PROFESSIONAL) {
          if ([
            EventType.NOTIFICATION_TYPE_PATIENT_CALL,
            EventType.NOTIFICATION_TYPE_DOCTOR_CALL,
          ].includes(notificationSubtype || "")) {
            if (patientId && userId) {
              await notifyPatientForDoctorCall({
                patientId: `${patientId}`
              })
              setSelectedNotification(notification);
              setOpenVideoCall(true);
            }
          }
        }
        break
      case NotificationType.medication:
        if (userCurrent?.roleSlug === ROLE.PATIENT) {
          setSelectedNotification(notification)
          history.push(routes.patientDashboard.url)
        } else {
          history.push(
            `${routes.clinicHistory.url}/patient/${notification.data.body.patientId}/daily-evolution?focusMessage=true`
          )
        }
        break
    }
  }

  const { markNotificationAsRead } = useMarkNotificationAsRead({
    onErrorCb: onErrorMarkAsRead,
    onSettledCb: onSettledMarkAsRead,
  })

  const { mutateAsync: createCustomNotification } =
    useCreateGeneralNotification()

  async function notifyDoctorForPatientCall({
    patientId
  }: {
    patientId: string
  }) {
    try {
      await createCustomNotification({
        json: {
          patientId
        },
        fromRole: ROLE.PATIENT,
        toRole: ROLE.HEALTH_PROFESSIONAL,
        type: EventType.NOTIFICATION_TYPE_PATIENT_CALL
      })
    } catch (e) {
      console.log({
        e
      });
    }
  }

  async function notifyPatientForDoctorCall({
    patientId
  }: {
    patientId: string
  }) {
    try {
      await createCustomNotification({
        json: {
          patientId
        },
        fromRole: ROLE.HEALTH_PROFESSIONAL,
        toRole: ROLE.PATIENT,
        type: EventType.NOTIFICATION_TYPE_SPECIALIST_CALL
      })
    } catch (e) {
      console.log({
        e
      });
    }
  }

  const handleCallNotificationClick = () => {
    markNotificationAsRead(`${notification.notificationId}`)
  }

  const handleAnnotation = () => {
    markNotificationAsRead(`${notification.notificationId}`)
    const newRoute = calculateNotificationActionRoute(notification, userCurrent?.roleSlug);
    if (newRoute) {
      markNotificationAsRead(`${notification.notificationId}`)
      history.push(`${newRoute}&noteId=${notification?.data?.body?.note?.id}`);
    } else {
      setSelectedNotification(notification)
    }
  }

  const handleDeviceReportNotificationClick = () => {
    const newRoute = calculateNotificationActionRoute(notification, userCurrent?.roleSlug);
    if (newRoute) {
      markNotificationAsRead(`${notification.notificationId}`)
      history.push(newRoute);
    } else {
      setSelectedNotification(notification)
      setOpenDeviceReport(true)
    }
  }

  const handleMedicationNotificationClick = () => {
    setSelectedNotification(null)
    markNotificationAsRead(`${notification.notificationId}`)
  }

  const markAsReadAndNavigate = () => {
    const newRoute = calculateNotificationActionRoute(notification, userCurrent?.roleSlug);
    if (newRoute) {
      markNotificationAsRead(`${notification.notificationId}`)
      history.push(newRoute);
    }
  }

  const handleNotificationAction = () => {
    switch (notification.notificationType) {
      case EventType.GROUP_NOTIFICATION_CALL:
        return handleCallNotificationClick()
      case EventType.GROUP_NOTIFICATION_MEDICATION:
        return handleMedicationNotificationClick()
      case EventType.GROUP_NOTIFICATION_REPORT:
        return handleDeviceReportNotificationClick()
      case EventType.GROUP_RECOMMENDATION:
        return markAsReadAndNavigate()
      case EventType.GROUP_PRESCRIPTION:
        return markAsReadAndNavigate()
      case EventType.GROUP_QUERY_ANNOTATIONS:
        return handleAnnotation();
      default:
        console.log("Not yet handled...", {
          notification
        });
        return;
    }
  };

  return { handleNotificationAction };
}