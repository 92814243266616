import Annotations from 'features/PatientDashboard/components/Annotations'
import Recommendations from './Recommendations'
import WorkPlanList from './WorkPlanList'
import { useEffect, useState } from 'react'
import { useGetAllPlanToday, useGetIntakeMedication } from '../hooks/userPlanToday'
import Loader from 'components/Loader'
import { PatientWorkPlanHeader } from './Header'
import { Props, TAB_SECTION_MAPPING, WORKPLAN_TABS } from './types'
import { AssignedPersonnel } from './AssignedPersonnel'
import { AssignedDevices } from './AssignedDevices'
import { PatientPlanAssignedPersonnel } from 'features/Notifications/services'
import { useLocation } from 'react-router-dom'

const PatientWorkPlan: React.FC<Props> = ({ patientId, userData }) => {
  const [listIntakeMedication, setlistIntakeMedication] = useState<any>([])
  const location = useLocation()

  const urlSearch = new URLSearchParams(location.search)
  const preSelectedTab = urlSearch.get('tab')
  let preSelectedTabChoice: WORKPLAN_TABS | null = null
  if (TAB_SECTION_MAPPING[WORKPLAN_TABS.WORK_PLAN].includes(`${preSelectedTab}` as any)) {
    preSelectedTabChoice = WORKPLAN_TABS.WORK_PLAN
  } else if (TAB_SECTION_MAPPING[WORKPLAN_TABS.DAILY_EVOLUTION].includes(`${preSelectedTab}` as any)) {
    preSelectedTabChoice = WORKPLAN_TABS.DAILY_EVOLUTION
  }

  const { data, isLoading: loadingGetAllPlanToday, refetch: planRefetch } = useGetAllPlanToday()
  const { mutateAsync: getIntakeMedication, isLoading: loadingGetIntakeMedication } = useGetIntakeMedication()
  const [selectedTab, setSelectedTab] = useState(preSelectedTabChoice || WORKPLAN_TABS.WORK_PLAN)

  const planToday = data?.data

  const fetchListMedication = async () => {
    if (data?.data?.currentService) {
      const listMedication = await getIntakeMedication(data.data.currentService.id)
      setlistIntakeMedication(listMedication?.data)
      planRefetch()
    }
  }
  const refetchPlan = () => {
    console.log('refetchPlan')
    planRefetch()
  }
  useEffect(() => {
    if (data) {
      fetchListMedication()
    }
  }, [data])

  const attendingSpecialist = (planToday?.assignedPersonnel || []).find((element: PatientPlanAssignedPersonnel) => {
    return element.isAttendingDoctor
  })

  return (
    <div>
      {!planToday?.currentService.id || planToday === undefined ? (
        <div className="h-[200px] flex items-center justify-center text-caption">
          {loadingGetAllPlanToday || (loadingGetIntakeMedication && <Loader />)}
          Lo sentimos, Usted no cuenta con ningun servicio.{' '}
        </div>
      ) : (
        <div>
          <div>
            <PatientWorkPlanHeader
              planToday={planToday}
              patientId={patientId}
              userData={userData}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          </div>
          <div>
            {selectedTab === WORKPLAN_TABS.WORK_PLAN ? (
              <div>
                <div className="grid sm:grid-cols-2 gap-4 mt-[24px]">
                  <div className="col-span-2 md:col-span-1">
                    {listIntakeMedication && (
                      <WorkPlanList
                        data={planToday?.medicaments}
                        listIntakeMedications={listIntakeMedication}
                        planId={planToday?.currentService?.id}
                        patientId={patientId}
                        serviceId={planToday?.currentService.id}
                        refetchPlan={() => refetchPlan()}
                      />
                    )}
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <Recommendations data={planToday?.recommendations} />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <AssignedPersonnel assignedPersonnel={planToday?.assignedPersonnel || []} />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <AssignedDevices devices={planToday?.patientDevices || []} />
                  </div>
                </div>
              </div>
            ) : null}
            {selectedTab === WORKPLAN_TABS.DAILY_EVOLUTION ? (
              <div>
                <div className="col-span-2 annotations-container">
                  <Annotations
                    data={planToday?.annotations}
                    planId={planToday?.currentService?.id}
                    patientId={patientId}
                    attendingSpecialist={attendingSpecialist}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  )
}
function getWindowSize() {
  const { innerWidth, innerHeight } = window
  return { innerWidth, innerHeight }
}

export default PatientWorkPlan
