import { Controller, useForm } from 'react-hook-form';
import InputSelect from 'components/InputSelect';
import { useEffect, useState } from 'react';
import CheckBox from 'components/CheckBox';
import IconDelete from 'assets/icons/icon_delete.svg';
import { SuppliesOptionsModel, SuppliesServiceModel } from '../hooks/useSupplies';
import IconButton from 'components/IconButton';
import InputDatePicker from 'components/InputDatePicker';
import Input from 'components/input';
import InputSelectSearch from 'components/InputSelectSearch'
import { AVAILABLE_VIAS, AVAILABLE_VIAS_MAPPED, SELECTABLE_QUANTITIES, SELECTABLE_SCHEDULE, setChangedData } from './utils';

interface PrescriptionElementProps {
  data: SuppliesServiceModel,
  supplyOptions: SuppliesOptionsModel[],
  onChange: (el: SuppliesServiceModel) => void;
  onDelete: () => void;
  isPlainForm: boolean;
  onChangeSearch: (value: string) => void;
  isLoadingSearch: boolean;
}


const PrescriptionElement: React.FC<PrescriptionElementProps> = ({
  data,
  supplyOptions,
  onChange,
  onDelete,
  isPlainForm,
  onChangeSearch,
  isLoadingSearch
}) => {
  const { control, setValue, watch } = useForm();
  const [currentTherapy, setCurrentTherapy] = useState<boolean>(false);
  const [medicationStartTime, setMedicationStartDate] = useState<any>();
  const [searchValue, setSearchValue] = useState<any>({ value: '', label: '', via: '', type: '' })
  const [daysOfHospitalization, setdaysOfHospitalization] = useState(' ');

  useEffect(() => {
    if (data) {
      loadValues();
    }
  }, []);

  const loadValues = () => {
    const selectedVia = AVAILABLE_VIAS_MAPPED[data?.via || ""]?.value || data?.via;
    setdaysOfHospitalization(data?.daysOfTreatment);
    setCurrentTherapy(!!data?.current_therapeutics);
    setValue('supplyId', data?.supplyId);
    setValue('quantity', data?.quantity);
    setValue('schedule', data?.schedule);
    setValue('via', selectedVia)
    setSearchValue({
      value: data?.supplyId,
      label: data?.supplyName,
      via: selectedVia,
      type: data?.type ? data?.type : data?.supplyType
    })
    if (data?.medicationStartTime) {
      const medTime = data?.medicationStartTime.split(':');
      const time = new Date();
      time.setHours(Number(medTime[0]) + Number(medTime[1].split(' ')[1].toLowerCase() === 'pm' ? 12 : 0))
      time.setMinutes(Number(medTime[1].split(' ')[0]))
      setMedicationStartDate(time);
    }
  }

  const handleChange = (field: any, value: any) => {
    const changeData = { ...data };
    setChangedData({ field, value, changeData, setdaysOfHospitalization });
    onChange(changeData);
  }

  const handleSelectChangeInfo = (e: any) => {
    handleChange('supplyIdSearch', e)
  }

  const handleInputSelectChange = (value: any) => {
    if (value.length > 0) {
      onChangeSearch(value)
    }
  };

  return (
    <div className='mt-[20px] border-b-solid border-b-[1px] pb-[20px]'>
      <div className='items-center flex  flex-col-reverse xl:flex-row	 xl:flex gap-2'>
        <div className='flex w-full'>
          <div className='w-full flex flex-col xl:flex-row	 xl:flex gap-2'
          >
            <div className="w-full xl:w-[20%]"
              data-testid="prescription_medicine"
            >
              <InputSelectSearch
                className={"w-full xl:min-w-[20%]"}
                label={isPlainForm ? 'Medicamento' : 'Medicamento / insumo'}
                value={searchValue}
                options={supplyOptions}
                required
                onSearch={handleInputSelectChange}
                onSelect={handleSelectChangeInfo}
                isLoading={isLoadingSearch}
              />
            </div>
            {searchValue?.type !== 2 &&
              <div className="w-full xl:w-[20%]"
              >
                <Controller
                  control={control}
                  name='via'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label="Vía de administración"
                      name="via"
                      required={false}
                      onSelect={val => {
                        onChange(val);
                        handleChange(name, val)
                      }}
                      value={value}
                      ref={ref}
                      data-testid="selector-via"
                    >
                      {
                        AVAILABLE_VIAS.map(element => <option value={element.value} >{element.label}</option>)
                      }
                    </InputSelect>
                  )} />
              </div>
            }
            <div className={`w-full ${(searchValue?.type !== 2) ? 'xl:w-[13%]' : 'xl:w-[18%]'}`}>
              <Controller
                control={control}
                name='quantity'
                render={({ field: { onChange, value, name, ref } }) => (
                  <InputSelect
                    label="Cantidades"
                    name="quantity"
                    required={false}
                    onSelect={val => {
                      onChange(val);
                      handleChange(name, val)
                    }}
                    value={value}
                    ref={ref}
                    data-testid="selector-quantity"
                  >
                    {
                      SELECTABLE_QUANTITIES.map(ELEMENT => <option key={ELEMENT.label} value={ELEMENT.value}>{ELEMENT.label}</option>)
                    }
                  </InputSelect>
                )}
              />
            </div>
            <div className={`w-full ${(searchValue?.type !== 2) ? 'xl:w-[13%]' : 'xl:w-[18%]'}`}>

              <Controller
                control={control}
                name='schedule'
                render={({ field: { onChange, value, name, ref } }) => (
                  <InputSelect
                    label="Horario"
                    name="schedule"
                    required={false}
                    onSelect={val => {
                      onChange(val);
                      handleChange(name, val)
                    }}
                    value={value}
                    ref={ref}
                    data-testid="prescription-schedule"
                  >
                    {
                      SELECTABLE_SCHEDULE.map(element => <option value={element.value}>{element.label}</option>)
                    }
                  </InputSelect>
                )}
              />
            </div>
            <div className={`w-full ${searchValue?.type !== 2 ? 'xl:w-[17%]' : 'xl:w-[22%]'}`} data-testid="prescription-date">

              <InputDatePicker
                label={"Hora de inicio"}
                onChange={(date: any) => {
                  setMedicationStartDate(date);
                  handleChange('medicationStartTime', date);
                }}
                selected={medicationStartTime}
                dateFormat="h:mm aa"
                showTimeSelect
                showTimeSelectOnly
                timeCaption="Hora"

              />
            </div>
            <div className={`w-full ${searchValue?.type !== 2 ? 'xl:w-[17%]' : 'xl:w-[22%]'}`} data-testid="treatment-duration">
              <Input
                label="Días de tratamiento"
                type="text"
                typeValidation='number'
                required
                value={data.daysOfTreatment}
                onChange={event => { handleChange('daysOfTreatment', event.target.value) }}
              />
            </div>
          </div>

        </div>
        <div className="w-full xl:w-max	 justify-start">
          <IconButton
            data-testid="button_delete_treatment"
            isActivable={true}
            icon={IconDelete}
            className="bg-error-main"
            onClick={onDelete}
            type="button"
          />
        </div>
      </div>
      <div className=" items-center flex  justify-between mt-[8px] 	">
        <CheckBox
          classNameCheck='h-[18px] w-[18px] min-w-[18px]'
          className='pl-[0px] text-caption text-text-primary'
          name="Terapéutica actual"
          selected={currentTherapy}
          onSelected={() => {
            setCurrentTherapy(!currentTherapy);
            handleChange('current_therapeutics', !currentTherapy);
          }}
        />
        <div className="text-body1 ">
          Cant Total: {(parseInt(watch('quantity')) * (24 / parseInt((watch('schedule')?.replace('every_', '')?.replace('h', '')))) * parseInt(daysOfHospitalization)) ? (parseInt(watch('quantity')) * (24 / parseInt((watch('schedule')?.replace('every_', '')?.replace('h', '')))) * parseInt(daysOfHospitalization)) : 0}
        </div>
      </div>

    </div>

  )
}

export default PrescriptionElement;
