import { useEffect, useState } from 'react';
import MedicosA from 'assets/images/no_result_a.png';
import Medicos from 'assets/images/no_result_b.png';
import { PatientModel } from 'features/Patient/models/Patient.model';
import CardUser from 'components/cardUser';
import { useGetAssignedPersonnelList, useGetListSpeciality, useGetListSpecialityRequest, usePostListSpecialityRequest, useDeleteSpecialistRequest } from '../hooks/useSpecialist';
import { t } from 'i18next';
import ImageError from 'components/ImageError';
import BtnMore from 'assets/icons/more.svg';
import BtnTrash from 'assets/icons/trash_b.svg';
import LoaderMini from 'components/LoaderMini';
import { onAlert } from 'components/Alert';
import Chip from 'components/Chip';
import Moment from "moment";
import CalendarIcon from 'assets/icons/icon_calendar.svg'
import ScrollToBottom from 'react-scroll-to-bottom';


interface WorkPlanProps {
  patientData?: PatientModel
}

const WorkPlan: React.FC<WorkPlanProps> = ({
  patientData
}) => {
  // typeSpecialist
  const [typeSpecialists, setTypeSpecialists] = useState([]);

  // specialists
  const [assignedPersonnelList, setAssignedPersonnelList] = useState<[]>([]);
  const [assignedPersonnelListRequest, setAssignedPersonnelListRequest] = useState<[]>([]);

  const { mutateAsync: getspecialities, isLoading: loadingGetspecialities } = useGetListSpeciality();
  const { mutateAsync: getspecialitiesRequest, isLoading: loadingGetspecialitiesRequest } = useGetListSpecialityRequest();
  const { mutateAsync: posttspecialitiesRequest } = usePostListSpecialityRequest();
  const { mutateAsync: getAssignedPersonnelList, isLoading: loadingGetAssignedPersonnelList } = useGetAssignedPersonnelList();
  const { mutateAsync: deletePersonnelListRequest } = useDeleteSpecialistRequest();


  useEffect(() => {
    if (patientData?.service?.id) {
      getSpecialities();
      getGetAssignedPersonnelList();
      getGetAssignedPersonnelListRequest();
    }
  }, [patientData?.service?.id]);


  const getSpecialities = async () => {
    const response = await getspecialities();
    setTypeSpecialists(response.data)
    if (response.data) {
    } else {
      onAlert.error(response);
    }
  }



  const getGetAssignedPersonnelList = async () => {
    const response = await getAssignedPersonnelList(patientData?.service?.id);

    if (response.data) {
      setAssignedPersonnelList(response.data);

    } else {
      onAlert.error(response);
    }
  }
  const getGetAssignedPersonnelListRequest = async () => {
    const response = await getspecialitiesRequest(patientData?.service?.id);

    if (response.data) {
      setAssignedPersonnelListRequest(response.data);

    } else {
      onAlert.error(response);
    }
  }
  const AddSpecialitiesListRequest = async (data: any) => {
/*     console.log(data);
 */
    let obj = {
      serviceId: patientData?.service?.id,
      body: { "specialtyId": data.id }
    }
    const response = await posttspecialitiesRequest(obj);

    if (response.data) {
      onAlert.success('Especialidad "' + data.name + '" solicitada  ');
      getSpecialities();
      getGetAssignedPersonnelList();
      getGetAssignedPersonnelListRequest();
    } else {
      onAlert.error(response);

    }

  }
  const deleteSpecialitiesList = async (data: any) => {
    let obj = {
      serviceId: patientData?.service?.id,
      specialistId: data.id,

    }
    const response = await deletePersonnelListRequest(obj);
    if (response.data) {
      onAlert.success('Solicitud "' + data.name + '" eliminada ');
      getSpecialities();
      getGetAssignedPersonnelList();
      getGetAssignedPersonnelListRequest();
    } else {
      onAlert.error(response);

    }

  }



  return (
    <div className='w-full'>

      <div className="grid sm:grid-cols-2 gap-4">
        <div className='col border-solid border-[1px] border-grey-200 rounded-[16px] p-4'>
          <p className='text-body1 row-span-1 col-span-full mb-4'>{t('select_a_specialty')}</p>

          <div className='row-span-1 col-span-full relative'>

            <div className="flex mt-[16px] mb-[16px]">
              <Chip variant='primary' className=' justify-center mr-[4px] ' label={`Desde : ${Moment(patientData?.service?.serviceDate).format('DD/MM/YYYY')} `} onClose={() => { }} />
              <Chip variant='primary' className=' justify-center ' label={`Hasta : ${Moment(patientData?.service?.plannedReleaseDate).format('DD/MM/YYYY')}`} onClose={() => { }} />

            </div>
          </div>
          <div className={`${loadingGetspecialities ? 'relative' : ''}  mt-4 flex flex-col `}>
            {loadingGetspecialities &&
              <LoaderMini />
            }

            {
              typeSpecialists.length === 0 ? (
                <ImageError className={'border-dashed border-[2px] rounded-[16px] min-h-[352px]	h-full  text-left text-caption '} image={MedicosA}>
                  No se encontraron tipos de especialistas disponibles.
                </ImageError>
              ) : (
                <ScrollToBottom
                  mode={'top'}
                  followButtonClassName='ScrollToBottom__btn__top'
                  scrollViewClassName=" overflow-y-scroll scrollbar grid 2xl:grid-cols-2 gap-2 "
                  className=" bg-white rounded-lg  w-full h-[352px]  "
                >
                  {typeSpecialists.map((el: any) =>
                    <CardUser
                      key={el.id +'typeSpecialists'}
                      id={el.id}
                      icon={BtnMore}
                      showBtn={true}
                      validate={true}
                      onlyText={true}
                      user={{ name: el.label, id: el.id, }}

                      onClick={(data: any) => {
                        AddSpecialitiesListRequest(data);

                      }}
                    />
                  )}
                </ScrollToBottom>)
            }</div>
        </div>
        
        <div className="col">
          {assignedPersonnelListRequest.length  !== 0 &&
          <div className={`${loadingGetspecialitiesRequest ? 'relative' : ''}  mb-4  border-solid border-[1px] border-grey-200 rounded-[16px] p-4 flex flex-col `}>
            {loadingGetspecialitiesRequest &&
              <LoaderMini />
            }
            <p className='text-body1 mb-2'>{t('requested_specialty')}</p>

            {
              assignedPersonnelListRequest.length === 0 ? (
                <ImageError className={'border-dashed border-[2px] rounded-[16px] min-h-[352px] h-full text-left text-caption 	'} image={Medicos}>
                  Aquí se mostrara los especialistas solicitados.
                </ImageError>
              ) : (
                <ScrollToBottom
                  followButtonClassName='ScrollToBottom__btn__top'
                  scrollViewClassName=" overflow-y-scroll scrollbar grid  gap-2 "
                  className=" bg-white rounded-lg  w-full h-[190px]  "
                >
                  {assignedPersonnelListRequest.map((e: any) =>
                    <CardUser
                      key={e.id +'assignedPersonnelListRequest'}
                      id={e.id}
                      icon={BtnTrash}
                      showBtn={true}
                      validate={true}
                      onlyText={true}
                      user={{ name: e.especialidad, id: e.id, }}

                      onClick={(data: any) => {
                        deleteSpecialitiesList(data);

                      }}
                    />
                  )}
                </ScrollToBottom>)
            }

          </div>
           }
          <div className={`${loadingGetAssignedPersonnelList ? 'relative' : ''} border-solid border-[1px] border-grey-200 rounded-[16px] p-4 flex flex-col `}>
            {loadingGetAssignedPersonnelList &&
              <LoaderMini />
            }
            <p className='text-body1'>{t('personal_allowance')}</p>

            {
              assignedPersonnelList.length === 0 ? (
                <ImageError className={'border-dashed border-[2px] rounded-[16px] min-h-[352px] h-full text-left text-caption 	'} image={Medicos}>
                  Aquí se mostrara los especialistas seleccionados.
                </ImageError>
              ) : (
                <ScrollToBottom
                  mode={'top'}
                  followButtonClassName='ScrollToBottom__btn__top'
                  scrollViewClassName=" overflow-y-scroll scrollbar grid 2xl:grid-cols-2 gap-2 "
                  className=" bg-white rounded-lg  w-full h-[170px]  "
                >
                  {assignedPersonnelList.map((e: any) =>
                    <CardUser
                      key={e.id+'assignedPersonnelList'}
                      showBotton={true}
                      user={{ ...e, description: e.specialty + ' - ' + (e.phone ? e.phone : e.email) }}
                      labelIcon={e.isAttendingDoctor !== 1 ? '' : 'Médico tratante'}
                      chips={e?.daysAvailable?.map((item: any) => {

                        return ({ label: item, icon: CalendarIcon })

                      })}
                    />
                  )}
                </ScrollToBottom>)
            }

          </div>
        </div>
      </div>
    </div >
  );
};

export default WorkPlan;