import React, { createContext, useState } from 'react'

import '../layout/index.scss'
import useUser from 'features/shared/hooks/useUser'
import HeaderLayout from './headerLayout'
import TermsConditions from './termsConditions'
import Loader from 'components/Loader'
import { ROLE, ROLE_LABELS } from 'global/constants/roles'
import Sidebar from './Sidebar/index';

interface Props {
  loader: boolean
}

const defaultProps: Props = {
  loader: false,
}

export const CountryContext = createContext('')

const Layout: React.FC<Props> = (props) => {
  const { user, tokensLoaded, userCurrent } = useUser()
  const [showMain, setShowMain] = React.useState(true)
  const [country, setCountry] = useState<any>('Todos')
  const disguiseSetShowMain = (value: boolean) => {
    setShowMain(value)
  }

  const getCountry = (country?: string) => {
    setCountry(country)
  }

  return (
    <CountryContext.Provider value={country}>
      <React.Fragment>
        {!userCurrent || !tokensLoaded ? (
          <Loader />
        ) : (
          <div className="flex ">
            <Sidebar>
              {userCurrent?.roleSlug === ROLE.PATIENT && (
                <TermsConditions
                  changeShowMain={disguiseSetShowMain}
                  userId={userCurrent?.id}
                />
              )}

              {showMain && (
                <main className="p-5 w-full h-full flex flex-col bg-bg1">
                  <HeaderLayout
                    user={userCurrent?.name}
                    userLastName={userCurrent?.lastname || ""}
                    role={userCurrent?.roleSlug}
                    roleName={ROLE_LABELS[userCurrent?.roleSlug] || userCurrent?.roleName}
                    countryFrom={
                      userCurrent?.ipress?.countryId || userCurrent?.countryId
                    }
                    getCountry={getCountry}
                  />
                  <div className="w-full mt-[32px]">{props.children}</div>
                </main>
              )}
            </Sidebar>
          </div>
        )}
      </React.Fragment>
    </CountryContext.Provider>
  )
}

Layout.defaultProps = defaultProps

export default Layout
