export enum NotificationType {
  call = 'call',
  report = 'report',
  medication = 'medication',
  prescription = 'prescription',
  recommendation = 'recommendation',
  consultation = 'consultation',
  queryWorkplan = 'queryWorkplan',
  annotations = 'annotations',
  attended = "attended",
  patientEvents = "patient-events",
}
export type EventType = string

export interface GetNotificationsParams {
  role: string
  from?: string
  to?: string
  type?: NotificationType
  page?: number
  attended?: boolean
  patientTextSearch?: string
  deviceSource?: string
}

export interface GetAttendedNotificationsParams
  extends GetNotificationsParams { }

export interface GetNotificationsResponse {
  data: GetNotificationsResponseData
}

export interface GetNotificationsResponseData {
  currentPage: number
  data: Notifications[]
  totalPages: number
}

export interface Notifications {
  date: string
  notifications: Notification[]
}

export interface Notification {
  notificationId: number
  name: string
  data: NotificationData
  createdAt: string
  readAt: null
  notificationType: NotificationType
  deviceSource?: string
  patientFirstName?: string
  patientLastName?: string
  patientGender?: string;
}

export interface NotificationData {
  body: NotificationDataBody
  title: string
}

export interface NotificationDataBody {
  device?: Device
  recommendation?: any
  eventType?: EventType
  type?: EventType
  userIdEmitter: number
  patientId?: number
  planId?: number
  consultation?: any
}

export interface Device {
  serial: string
  reviewed: number
  deviceName: string
  patientName: string
  planDeviceId: number
  comment?: string
  specialistName?: string
}

export interface PatientPlanDevice {
  code: string,//"3232",
  product: string,//"BIOBEAT RELOJ",
  quantity: number,//1,
  patient: string,//"PACIENTEFNAA PACIENTELNAA",
  createdAt: string,//"2024-11-14",
  type: string,//"Dispositivo",
  measurement: string,//"UN",
  deliveryStatus: number, //0,
  planId: number, //757,
  deviceId: number, //3272,
  serial: string,//"12312312312312312312"
}

export interface PatientPlanAssignedPersonnel {
  id: number,//459,
  name: string; //"Barry",
  lastname: string; //"Rosen",
  phone: string; //"998877889",
  email: string; //"barec60782@tingn.com",
  roleId: number,//4,
  specialty: string; //"Medico General",
  serviceId: number,//707,
  specialistId: number,//459,
  isAttendingDoctor: number,//1,
  daysAvailable: string; //"2024-09-25,2024-09-24"
}

export interface GetNotificationsSummaryTotalParams {
  role: string
}
export interface GetNotificationsSummaryTotalResponse {
  data: GetNotificationsSummaryTotalResponseData
}

export interface GetNotificationsSummaryTotalResponseData {
  consultations: number
  calls: number
  medications: number
  devices: number
  prescriptions: number
  recommendations: number
  annotations?: number
  workplan?: number;
}

export interface GetNotificationsSummaryParams extends GetNotificationsSummaryTotalParams { }

export interface GetNotificationsSummaryResponse {
  data: GetNotificationsSummaryResponseData
}

export interface GetNotificationsSummaryResponseData {
  total: number
  notifications: NotificationSummary[]
}

export interface NotificationSummary {
  notificationId: number
  name: string
  data: NotificationSummaryData
  createdAt: string
  readAt: null
  notificationType: NotificationType
}

export interface NotificationSummaryData {
  title: string
  body: NotificationSummaryDataBody
}

export interface NotificationSummaryDataBody {
  device?: Device
  planId: number
  eventType?: EventType
  type?: EventType
  userIdEmitter: number
  patientId?: number
  note?: {
    createdAt: string;
    id: number;
    lastname: string;
    message: string;
    name: string;
    sender: string;
    speciality: string;
    userId: number,
  }
}

export interface GetAttendedNotificationsResponse {
  data: GetAttendedNotificationsResponseData
}

export interface GetAttendedNotificationsResponseData {
  currentPage: number
  data: AttendedNotifications[]
  totalPages: number
}

export interface AttendedNotifications {
  date: string
  notifications: AttendedNotification[]
}

export interface AttendedNotification {
  notificationId: number
  name: string
  data: AttendedNotificationData
  readAt: string
  createdAt: string
  attendedName: string
  notificationType: NotificationType
}

export interface AttendedNotificationData {
  body: AttendedNotificationDataBody
  title: string
}

export interface AttendedNotificationDataBody {
  device?: Device
  eventType: string
  patientId?: number
  userIdEmitter: number
}

export interface SendNotificationReportDevicePayload {
  userId: number
  planDeviceId: number
}
