import { PatientPlanAssignedPersonnel } from "features/Notifications/services";
import { PersonSnippet } from "../components/PersonSnippet";

export const PersonnelCard = ({
    record
}: {
    record: PatientPlanAssignedPersonnel
}) => {
    const endDate = record.daysAvailable ? record.daysAvailable.split(",")[record.daysAvailable.split(",").length - 1] : null;
    const startDate = record.daysAvailable ? record.daysAvailable.split(",")[0] : null;
    return (
        <div className="box-border flex flex-row items-center p-4 gap-4 bg-white border border-[#E2E2E2] rounded-xl flex-none order-1 flex-grow-0">
            <div className="flex flex-1">
                <PersonSnippet
                    name={record.name}
                    lastname={record.lastname}
                    role={record.specialty}
                />
            </div>
            <div className="flex flex-1 border-l border-[#E2E2E2] h-100 pl-2 flex flex-col">
                <div className="font-poppins font-normal text-[12px] text-[#545454] ml-1 mr-2">Asignado</div>
                <div className="inline-block w-full">
                    <span className="bg-[#F6F6F6] rounded-[16px] font-poppins font-medium text-[12px] text-[#585A5B] pl-2 pr-2 pt-1 pb-1 w-fit inline-block">
                        {startDate || "-"}
                    </span>
                    <div className="inline-block">
                        -
                    </div>
                    <span className="bg-[#F6F6F6] rounded-[16px] font-poppins font-medium text-[12px] text-[#585A5B] pl-2 pr-2 pt-1 pb-1 w-fit inline-block">
                        {endDate || "-"}
                    </span>
                </div>
            </div>
        </div>
    )
}