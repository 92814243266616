import ImageError from "components/ImageError"
import EmptyIcon from 'assets/icons/icon_empty.svg'
import useUser from "features/shared/hooks/useUser"
import { ROLE } from "global/constants/roles"

export const NoResultsFound = () => {
    const { userCurrent } = useUser()
    return (
        <div className={'flex flex-row justify-center mt-6 mb-6 p-5'}>

            <ImageError className={'border-dashed border-[2px] rounded-[16px] min-h-[352px]	h-full  text-left text-caption '} image={EmptyIcon}>
                <br />
                <br />
                <br />
                {userCurrent?.roleSlug === ROLE.HEALTH_PROFESSIONAL ?
                    'No tienes pacientes para esta fecha.' :
                    'No tienes pacientes.'
                }
            </ImageError>
        </div>
    )
}