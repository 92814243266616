import React from "react";

interface WorkplanPatientCardProps {
  label?: string;
  description?: string;
  className?: string;
}

const WorkplanRecommendationsPatientCard: React.FC<WorkplanPatientCardProps> = ({
  label,
  description,
  className,
}) => {
  return (
    <div className={` mb-[8px] p-[16px] sm:mh-[76px] rounded  rounded-l-lg  ${className}`}>
      <div className='font-poppins font-normal text-[12px] flex items-center text-[#141414]'>{label}</div>
      <div className='font-poppins font-medium text-[16px] text-[#141414]'>{description}</div>
    </div>
  )
}

export default WorkplanRecommendationsPatientCard;