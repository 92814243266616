import MedicalDevices from './MedicalDevices';
import { PatientModel } from 'features/Patient/models/Patient.model';
import Supplies from './Supplies/index';
import MedicalRecommendations from './MedicalRecommendations';
import SpecialistAssignment from './SpecialistAssignment';
import SpecialistAddRquest from './SpecialistAddRquest';
import useUser from 'features/shared/hooks/useUser';
import { ROLE } from 'global/constants/roles';

interface WorkPlanProps {
  patientData?: PatientModel
}

const WorkPlan: React.FC<WorkPlanProps> = ({
  patientData
}) => {
  const { userCurrent } = useUser();
/*   console.log(userCurrent?.roleSlug);
  console.log(ROLE.HEALTH_PROFESSIONAL); */

  return (
    <div className='grid grid-cols-1 gap-4'>
      {userCurrent?.roleSlug === ROLE.SERVICE_ADMIN &&
        <SpecialistAssignment
          patientData={patientData} />
      }
      {userCurrent?.roleSlug === ROLE.HEALTH_PROFESSIONAL &&
        <SpecialistAddRquest
          patientData={patientData} />
      }
      <MedicalDevices
        patientData={patientData}
      />
      <Supplies
        isPlainForm={false}
        patientData={patientData}
      />
      <MedicalRecommendations
        patientData={patientData}
      />
    </div>
  );
};

export default WorkPlan;