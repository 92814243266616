import React, { useEffect, useState } from "react";

interface WorkplanPatientCardProps {
  commentary?: string;
  description?: string;
  typeColor: number;
}

const WorkplanPatientCard: React.FC<WorkplanPatientCardProps> = ({
  commentary,
  description,
  typeColor
}) => {
  const [ color, setColor ] = useState('bg-[#F6F6F6] text-[#044EA8] border-[#044EA8]');

  useEffect(() => {
    switch (typeColor) {
      case 1:
        setColor('bg-[#E9FCFF] text-[#008EA3] border-[#008EA3]');
      break;
      case 2:
        setColor('bg-[#EFF3FE] text-[#00B1CC] border-[#00B1CC]');
      break;
      case 3:
        setColor('bg-[#EFF3FE] text-[#00B1CC] border-[#00B1CC]');
      break;
      case 4:
        setColor('bg-[#F9FDFF] text-[#648A99] border-[#648A99]');
      break;
      case 5:
        setColor('bg-[#F6FBFF] text-[#1A4F73] border-[#1A4F73]');
      break;
      case 6:
        setColor('bg-[#FFEEE6] text-[#FC5209] border-[#FC5209]');
      break;
      case 7:
        setColor('bg-[#FFFFFF] text-[#ADADAD] border-[#ADADAD]');
      break;
      default:
        setColor('bg-[#F6F6F6] text-[#044EA8] border-[#044EA8]');
      break;
    }

  }, [typeColor]);

  return (
    <div className={`border-l-[8px] mb-[16px] px-[17px] py-[24px] sm:mh-[90px] rounded  rounded-l-lg ${color}`}>
      <div className='text-left font-poppins font-normal text-[12px] text-[#044EA8]'>{commentary}</div>
      <div className='text-left font-poppins font-medium text-[16px] text-[#044EA8]'>{description}</div>
    </div>
  )
}

export default WorkplanPatientCard;