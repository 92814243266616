import "./TableUpperActions.css";
import CardDate from "features/ClinicHistory/ListPatients/CardDate/CardDate";
import moment from 'moment';
import { useEffect, useState } from "react";

const defaultMinDate = moment().subtract(2, "year").toDate();
const defaultMaxDate = moment().add(2, "day").toDate();

export interface ICardDateProps {
  startDateRange?: Date,
  endDateRange?: Date
}

const defaultDateRange = {
  startDateRange: undefined,
  endDateRange: undefined
};
export const SelectDateRangeFunction = ({
  onDateSelected,
  label,
  id,
  minDate = defaultMinDate,
  maxDate = defaultMaxDate,
  initialDateRange = defaultDateRange
}: {
  onDateSelected: (data: ICardDateProps) => void
  label: string;
  id: string;
  minDate?: Date;
  maxDate?: Date;
  initialDateRange?: ICardDateProps
}) => {
  const [selectedDate, setSelectedDate] = useState<ICardDateProps>(initialDateRange);

  useEffect(() => {
    if(selectedDate !== defaultDateRange){
      onDateSelected(selectedDate)
    }
  }, [selectedDate])

  return (<div id={`TableUpperActionsDatePickerParent_${id}`} style={{
    position: "relative",
    display: "flex",
    justifyContent: "left",
    alignItems: "end",
    WebkitAlignItems: "center"
  }} className="TableUpperActionsDatePickerParent w-full">
    <div className="CardDateContainer w-full">
      <CardDate
        setData={(data) => {
          setSelectedDate(data)
        }}
        id={`TableUpperActionsCardDate_${id}`}
        minDate={minDate}
        maxDate={maxDate}
        initialEndDateRange={initialDateRange?.endDateRange}
        initialStartDateRange={initialDateRange?.startDateRange}
        onCanceled={() => {
          setSelectedDate({
            startDateRange: undefined,
            endDateRange: undefined
          })
        }}
        label={label}
      />
    </div>
  </div>)
}