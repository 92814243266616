import IconClose from 'assets/icons/icon_close.svg';

interface ChipProps {
  variant?: string | "primary" | "secondary" | "tertiary" | "negative" | "ghost";
  icon?: any;
  label?: string;
  icon2?: any;
  label2?: string;
  onClose: () => void;
  isRemovable?: boolean;
  className?: string;
}

const Chip: React.FC<ChipProps> = ({
  onClose,
  isRemovable,
  className,
  icon,
  icon2,
  label,
  label2,
  variant,
}) => {
  const filterActive = 'invert(46%) sepia(76%) saturate(4317%) hue-rotate(161deg) brightness(88%) contrast(101%)';
  const defineVariant = () => {
    switch (variant) {
      case 'primary':
        return `
        h-8 flex items-center px-4 py-2 gap-2 bg-cyan-50 border-[1px] border-solid border-cyan-100 rounded-full text-caption text-primary-dark
        `;
      // hover:bg-primary-main hover:border-primary-main 
      // focus:border-primary-main focus:bg-primary-dark 
      case 'secondary':
        return `
        h-8 flex items-center px-4 py-2 gap-2 bg-primary-main border-[1px] border-solid border-primary-light rounded-full text-caption text-white
        `;
      case 'tertiary':
        return `
        h-[32px] flex items-center px-4 py-2 gap-2 bg-grey-50 border-[1px] border-solid border-grey-50 rounded-full text-caption text-bluedark-400
        `;
        case 'four':
        return `
        h-[32px] flex justify-center	 items-center px-4 py-2 gap-2 bg-[#FFFAF0] border-[1px] border-solid border-[#FFFAF0] rounded-full text-[#E5B51F] 
        `;
      default:
        if(className){
          return `h-8 flex items-center px-4 py-2 gap-2 border-[1px] border-solid border-cyan-100 rounded-full`;  
        }
        return `h-8 flex items-center px-4 py-2 gap-2 bg-cyan-50 border-[1px] border-solid border-cyan-100 rounded-full text-caption text-primary-dark`;
    }
  }
  return (
    <div className={`${defineVariant()} ${className}`}>
      {icon &&
        <img className='w-[16px] h-[16px]' src={icon} alt={icon} />

      }
      <span>{label}</span>
      {icon2 &&
        <div className='flex'>
          / <img className='w-[16px] h-[16px] ml-[5px]' src={icon2} alt={icon2} />
        </div>


      }
      <span>{label2}</span>
      {isRemovable && <div onClick={() => onClose()}><img src={IconClose} alt="cerrar" style={{ filter: filterActive }} className="h-4 w-4 cursor-pointer" /></div>}
    </div>
  )
}

export default Chip;