import LabelForm from "components/LabelForm";
import IconFile from "assets/icons/icon_file.svg";
import IconPlusCircle from "assets/icons/icon_plus_circle.svg";
import IconMeet from "assets/icons/icon_meet.svg";
import TextArea from "components/TextArea";
import { useForm } from "react-hook-form";
import ButtonToggle from "components/ButtonToggle";
import Avatar from "components/Avatar";
import NewMessageModal from "features/ClinicHistory/compontents/NewMessageModal";
import React, { useEffect, useState } from "react";
import { useGetDailyEvolution, useCreateDailyEvolution } from "features/ClinicHistory/hooks/useDailyEvolution";
import { DailyEvolutionModel } from "../../types/dailyEvolution"
import { MeetingVideoCall } from "components/MeetingVideoCall";
import AnnotationMedical from "features/ClinicHistory/compontents/AnotationsMedical";
import useUser from "features/shared/hooks/useUser";
import ScrollToBottom from "react-scroll-to-bottom";
import AnnotationsCard from "components/AnnotationsCard";
import { useCreateNotificationCallPatient } from "features/ClinicHistory/hooks/useNotification"
import { useTranslation } from "react-i18next";
import { ROLE } from "global/constants/roles";
import { onAlert } from "components/Alert";
import { useHistory } from "react-router-dom";

interface Props {
  serviceId?: number
  patientId: number
  userId: number
  userProfile?: number
  userData: any
}

const DailyEvolution: React.FC<Props> = ({ serviceId, patientId, userProfile, userId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { register } = useForm()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { mutateAsync: asyncGetDailyEvolution } = useGetDailyEvolution()
  const { mutateAsync: asyncCreateDailyEvolution, isLoading } = useCreateDailyEvolution()
  const [evolutionData, setEvolutionData] = useState<DailyEvolutionModel>()
  const { userCurrent } = useUser();
  const [isCall, setIsCall] = useState(false);
  const { mutateAsync: createNotificationPatient } = useCreateNotificationCallPatient()
  /*   const planToday = data?.data;
   */
  const shouldFireCall = () => {
    const params = new URLSearchParams(window.location.search)
    return params.get('meeting') === 'true'
  }

  const onGetEvolution = async () => {
    const dailyEvolution = await asyncGetDailyEvolution(String(serviceId));
    if (dailyEvolution?.data) {
      setEvolutionData(dailyEvolution.data);
    } else {
      onAlert.error(String(dailyEvolution));
    }
  }

  const onSubmit = async (data: any) => {
    const formatData = { ...data };
    formatData['serviceId'] = serviceId
    formatData['doctorId'] = userCurrent?.id
    const response = await asyncCreateDailyEvolution(formatData);
    if (response?.data) {
      setOpenModal(false);
      onGetEvolution()
    } else {
      onAlert.error(response);
    }
  }

  useEffect(() => {
    if (serviceId) {
      onGetEvolution()
    }
  }, [serviceId])
  const handleClickCallPatient = () => {
    /*     console.log('handleClickCallPatient');
     */
    setIsCall(false)
    setTimeout(async () => {

      const notificationPatient = await createNotificationPatient({
        patientId,
        userId

      });

      if (notificationPatient?.data) {
/*         history.push(`${routes.clinicHistory.url}/patient/${patientId}/daily-evolution`) 
 */        setIsCall(true)

      } else {
        onAlert.error("Hubo un error al realizar la llamada. El paciente aún no se ha registrado en la plataforma.")
      }
    }, 500)
  }
  const handleCloseMeeting = () => {
    setIsCall(false)
  }




  return (
    <div>
      <LabelForm icon={IconFile} label="Diagnóstico principal de ingreso" className='mt-4 mb-2 row-span-1 col-span-full' />

      <TextArea
        disabled
        rows={1}
        value={evolutionData?.admissionDiagnosis}
        height={'max-h-[80px]'}
        {...register('storySick')}
      />
      <br></br>
      {openModal &&
        <NewMessageModal
          showModal={openModal}
          onCloseModal={() => { setOpenModal(false) }}
          onSubmitForm={onSubmit}
          isLoading={isLoading}
        />
      }

      <div className='pt-4'>
        {console.log(patientId, '____ patientId ::::', shouldFireCall())}
        {
          (patientId && shouldFireCall() || isCall) &&
          <MeetingVideoCall
            planId={serviceId}
            userId={patientId}
            userProfile={userProfile}
            getIsClose={handleCloseMeeting}
            initCall={true}
          />
        }
      </div>
      <div className='justify-content items-start'>
        <AnnotationMedical
          className='w-[100%] mr-4 pb-4'
          planId={serviceId}
          patientId={patientId}
          patientView={false}
          buttonToggle={true}
          buttonToggleName={'Llamar'}
          buttonToggleIcon={IconMeet}
          buttonToggleFuntion={() => handleClickCallPatient()}
        />
        <div className='w-[100%] h-[490px] border-solid border-[1px] border-grey-200 rounded-[16px] p-4 flex flex-col'>
          <div className='flex items-center justify-between'>
            <LabelForm icon={IconFile} label="Descripción de evolución diaria" className='mt-4 mb-2 row-span-1 col-span-full' />
            {(userCurrent?.roleSlug === ROLE.HEALTH_PROFESSIONAL || userCurrent?.roleSlug === ROLE.DOCTOR) && (
              <ButtonToggle icon={IconPlusCircle} isActive={true} onClick={() => setOpenModal(true)} className="h-[40px] rounded-[8px]" >Agregar</ButtonToggle>
            )}
          </div>
          {
            evolutionData && evolutionData?.evolutions?.length > 0 ? (
              <ScrollToBottom followButtonClassName='ScrollToBottom__btn' scrollViewClassName=' overflow-y-scroll scrollbar' className=" bg-white rounded-lg  w-full h-[385px] ">
                <div className=' pr-[16px]'>
                  {evolutionData?.evolutions.map((daily, index) => (
                    <React.Fragment key={index}>
                      <Avatar
                        name={daily?.name}
                        role={daily?.specialty}
                        image={daily?.image}
                        className={`mb-2  justify-start`}
                      />
                      <AnnotationsCard
                        commentary={`Comentario (${daily?.createdAt})`}
                        description={daily?.description}
                        className={`bg-[#F6F6F6] mb-2`}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </ScrollToBottom>

            ) : (
              <p className='h-full flex mx-auto items-center'>Agregue una descripción</p>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default DailyEvolution;
