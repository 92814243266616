import { NotificationSummary } from "features/Notifications/services";
import { EventType } from "global/constants/eventType";
import { ROLE } from "global/constants/roles";
import { routes } from "routes/routing";

export const calculateNotificationActionRoute = (notification: NotificationSummary, currentUserRoleSlug: string) => {
    const eventType = notification.data?.body?.eventType || notification.data?.body?.type;
    switch (eventType) {
        case EventType.NOTIFICATION_TYPE_RECOMMENDATION:
            switch (currentUserRoleSlug) {
                case ROLE.PATIENT:
                    return routes.patientDashboard.url
            }
            break;
        case EventType.NOTIFICATION_TYPE_PRESCRIPTION_DELETED:
        case EventType.NOTIFICATION_TYPE_PRESCRIPTION_UPDATED:
        case EventType.NOTIFICATION_TYPE_PRESCRIPTION_CREATED:
            switch (currentUserRoleSlug) {
                case ROLE.PATIENT:
                    return routes.patientDashboard.url
            }
            break;
        case EventType.NOTIFICATION_TYPE_DEVICE_ASSIGNED:
            switch (currentUserRoleSlug) {
                case ROLE.PATIENT:
                    return routes.patientDashboard.url
            }
            break;
        case EventType.NOTIFICATION_TYPE_MEDICAL_PERSONAL_ASSIGNED:
            switch (currentUserRoleSlug) {
                case ROLE.PATIENT:
                    return routes.patientDashboard.url
            }
            break;
        case EventType.NOTIFICATION_TYPE_NOTE_PATIENT:
            switch (currentUserRoleSlug) {
                case ROLE.PATIENT:
                    return routes.patientDashboard.url
            }
            break;
    }
}