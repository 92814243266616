import { useMutation } from 'react-query'
import { createNotificationEmergency, createNotificationCallDoctor, createNotificationCallPatient, IGeneralNotificationPayload, createGeneralNotification } from '../services/notification'
import { NotificationCallDoctorPost, NotificationEmergencyPost, NotificationCallPatientPost } from '../types/notification';

export function useCreateNotificationEmergency() {
  return useMutation((data: NotificationEmergencyPost) => createNotificationEmergency(data));
}
export function useCreateNotificationCallDoctor() {
  return useMutation((data: NotificationCallDoctorPost) => createNotificationCallDoctor(data));
}
export function useCreateNotificationCallPatient() {
  return useMutation((data: NotificationCallPatientPost) => createNotificationCallPatient(data));
}
export function useCreateGeneralNotification() {
  return useMutation((data: IGeneralNotificationPayload) => createGeneralNotification(data));
}