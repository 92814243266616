import Input from "components/input";
import { InputProps } from '../input'
import { BehaviorSubject, debounceTime } from "rxjs";
import { useEffect, useState } from "react";

export const inputChange = new BehaviorSubject("");
export const inputChange$ = inputChange.asObservable();

export const InputTextDebounced = ({
    inputProps,
    onDebouncedValue,
    debounceMillis,
    defaultValue
}: {
    inputProps?: InputProps,
    onDebouncedValue: (data: string) => void
    debounceMillis: number,
    defaultValue?: string
}) => {
    const [originalValue, setOriginalValue] = useState<string>(defaultValue || "");
    useEffect(() => {
        const searchSubscription = inputChange$
            .pipe(debounceTime(debounceMillis))
            .subscribe(() => {
                onDebouncedValue(originalValue);
            })
        return () => {
            return searchSubscription.unsubscribe()
        }
    }, [originalValue]);
    return (
        <div>
            <Input
                {
                ...inputProps
                }
                value={originalValue}
                onChange={(e: any) => {
                    setOriginalValue(e.target.value || "")
                }} />
        </div>
    )
}