import React from 'react'
import IconClose from 'assets/icons/icon_close.svg'
import Button from 'components/Button'
import Chip from 'components/Chip'
import Avatar from 'components/Avatar'
import TextArea from 'components/TextArea'
import { Device } from '../services'

interface ReportDeviceProps {
  onCloseModal: () => any
  onSubmitForm?: () => any
  deviceSelected?: Device
}

const ReportDevice: React.FC<ReportDeviceProps> = (props) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative py-8 px-6 w-full max-w-[448px] ">
          <div className="border-0 rounded-lg shadow-lg relative w-full  bg-white outline-none focus:outline-none overflow-hidden p-4">
            <div className="border-b-[1px] border-[#14141426] pb-[24px] flex justify-between items-start mb-4 w-full">
              <div className="flex items-center gap-4 w-full">
                <div className='flex-1 w-[50%]'>
                  <span className="text-subtitle1 break-words">
                    {props.deviceSelected?.deviceName}
                  </span>
                  <br />
                  <span className="text-caption h-4 font-poppins font-normal text-[12px] tracking-[-0.2px] text-[#585A5B] break-words">
                    COD: {props.deviceSelected?.serial}
                  </span>
                </div>
                <div className='-mt-5 flex-1 flex justify-end'>
                  {false ? (
                    <Chip
                      label="Reportado"
                      onClose={() => { }}
                      isRemovable={false}
                      className="bg-[#FFEFED] text-[#E21010] text-[12px] font-medium decoration-skip-ink-none w-fit"
                    />
                  ) : (
                    <Chip
                      label="Revisado"
                      onClose={() => { }}
                      isRemovable={false}
                      className='bg-cyan-50 text-[12px] font-medium decoration-skip-ink-none w-fit'
                    />
                  )}
                </div>
              </div>
              <button
                onClick={() => props.onCloseModal()}
                className="h-[30px] w-[30px] flex items-center justify-center"
              >
                <img src={IconClose} alt="close" className="min-w-[1.5rem] min-h-[1.5rem] w-6 h-6" />
              </button>
            </div>

            <div className="mb-4">
              <p className="text-body2 pb-2 font-poppins text-[14px] font-medium leading-6 tracking-[0.2px] text-left decoration-skip-ink-none">Paciente:</p>
              <Avatar name={props.deviceSelected?.patientName} className={'font-poppins text-[14px] font-medium leading-6 tracking-[0.2px] text-left decoration-skip-ink-none'} />
            </div>
            <div>
              <p className="text-body2 pb-2 font-poppins text-[14px] font-medium leading-6 tracking-[0.2px] text-left decoration-skip-ink-none">Reportado por:</p>
              <Avatar
                name={props.deviceSelected?.specialistName}
                role="Medico General"
                className={'font-poppins text-[14px] font-medium leading-6 tracking-[0.2px] text-left decoration-skip-ink-none'}
              />
            </div>

            <div className="mt-6">
              <TextArea
                label="Comentario"
                value={props.deviceSelected?.comment}
                readOnly
              />
            </div>

            <div className="flex items-center justify-center w-full mt-6">
              <Button
                onClick={() => {
                  props.onSubmitForm?.()
                  props.onCloseModal()
                }}
                variant="primary"
                size="large"
                type="button"
                disabled={
                  props.deviceSelected?.reviewed !== 0 ||
                  props.onSubmitForm === undefined
                }
              >
                Revisado
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default ReportDevice

