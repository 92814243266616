import useUser from "features/shared/hooks/useUser"
import Button from 'components/Button'
import Input from "components/input"
import SearchIcon from 'assets/icons/icon_search.svg'
import InputSelect from "components/InputSelect"
import { ICardDateProps, SelectDateRangeFunction } from "./TableUpperActions.utils"
import { useEffect, useState } from "react"
import useGetLocations, { LocationModel } from "features/shared/hooks/useGetLocations"
import { useHistory } from "react-router-dom"
import { routes } from "routes/routing"
import { ALLOWED_SERVICE_STATES_LABELS, DEFAULT_ALL_SERVICE_STATES, SERVICE_STATES } from "features/shared/types/serviceStates"

export interface IFiltersData {
  region?: string
  text?: string
  attentionDatesRange?: ICardDateProps
  releaseDatesRange?: ICardDateProps
  isCheckedSearchCountry?: boolean
  serviceStatusIn?: number[]
  patientId?: string
  specialistId?: string
}

export enum ALLOWED_FILTERS {
  ATTENTION_DATE = 'attentionDate',
  RELEASE_DATE = 'releaseDate',
  ATTENTION_STATUS = 'attentionStatus',
  REGION = 'region',
  TEXT = 'text',
  TEXT_ATTENTION = 'textAttention',
  NOTIFICATION_PATIENT = 'notificationPatient',
  NOTIFICATION_TYPE = 'notficationType',
  NOTIFICATION_DATE = 'notificationDate',
}

export enum EXTRA_ACTIONS {
  ADD_PATIENT = 'addPatient'
}

export const TableUpperActionFilterContainer = (props: React.PropsWithChildren<any>) => {
  return (
    <div className="flex flex-col	min-h-[56px]" style={{
      ...(props.style || {})
    }}>
      <div className="w-full">
        {props.children}
      </div>
    </div>
  )
}

export function TableUpperActions({
  onFiltersChanged,
  filters,
  extraActions,
  patientsNumber,
  resultsLabel
}: {
  onFiltersChanged: (data: IFiltersData) => void
  filters: ALLOWED_FILTERS[]
  extraActions: EXTRA_ACTIONS[]
  patientsNumber?: string,
  resultsLabel: string
}) {
  const { userCurrent } = useUser()
  const [selectedAttentionDateRange, setSelectedAttentionDateRange] = useState<ICardDateProps | undefined>()
  const [selectedReleaseDateRange, setSelectedReleaseDateRange] = useState<ICardDateProps | undefined>()
  const [region, setSelectedRegion] = useState<string | undefined>();
  const [attentionStatus, setAttentionStatus] = useState<number[] | undefined>([SERVICE_STATES.PENDING, SERVICE_STATES.IN_PROGESS, SERVICE_STATES.FINISHED]);
  const [searchText, setSearchText] = useState<string>('')
  const history = useHistory();

  const getListLocations = useGetLocations();
  const [listProvinces, setListProvinces] = useState<LocationModel[]>([]);

  const onGetListLocations = async () => {
    const response = await getListLocations.mutateAsync({ countryId: userCurrent?.ipress?.countryId, parentId: 0 });
    if (response?.data?.length > 0) {
      setListProvinces(response.data);
    }
  }

  useEffect(() => {
    if (userCurrent) {
      onGetListLocations()
    }
  }, [userCurrent])

  useEffect(() => {
    onFiltersChanged({
      region,
      text: searchText,
      attentionDatesRange: selectedAttentionDateRange,
      releaseDatesRange: selectedReleaseDateRange,
      serviceStatusIn: attentionStatus
    })
  }, [selectedAttentionDateRange, selectedReleaseDateRange, region, searchText, attentionStatus])

  return (<div className='px-4 pb-[24px] flex flex-col justify-between lg:flex-row xl:flex-row'>
    <div className="flex flex-row justify-left items-center">
      <p className="mr-4">
        {' '}
        <span className="font-medium text-text-primary" data-testId="pacientes-label">
          {resultsLabel}
        </span>
        <span className="ml-2 mr-2">|</span>
        <span data-testId="pacientes-number-label">
          {patientsNumber || 0}
        </span>
      </p>
      {
        extraActions.includes(EXTRA_ACTIONS.ADD_PATIENT) && <div>
          <Button
            data-testid="patients__add_patient"
            variant="primary"
            onClick={() => {
              history.push(routes.newPatient.url)
            }}
            style={{
              background: "#00B1CC",
              border: "none"
            }}
          >
            Añadir paciente
          </Button>
        </div>
      }
    </div>
    {<div className={`flex flex-col sm:flex-row	 sm:grid sm:grid-cols-${filters.length > 4 ? '3' : filters.length} xl:grid-cols-${filters.length} gap-5`}>
      {
        filters.map(element => {
          switch (element) {
            case ALLOWED_FILTERS.ATTENTION_DATE:
              return <TableUpperActionFilterContainer key={element}>
                <SelectDateRangeFunction
                  key={ALLOWED_FILTERS.ATTENTION_DATE}
                  data-testId="buscar-paciente-testid-fecha-atencion"
                  label="Fecha de atención"
                  id="attentionDate"
                  onDateSelected={(data) => {
                    setSelectedAttentionDateRange(data);
                  }} /></TableUpperActionFilterContainer>
            case ALLOWED_FILTERS.RELEASE_DATE:
              return <TableUpperActionFilterContainer key={element}>
                <SelectDateRangeFunction
                  key={ALLOWED_FILTERS.RELEASE_DATE}
                  data-testId="buscar-paciente-testid-fecha-salida"
                  label="Fecha de Alta"
                  id="releaseDate"
                  onDateSelected={(data) => {
                    setSelectedReleaseDateRange(data);
                  }} />
              </TableUpperActionFilterContainer>
            case ALLOWED_FILTERS.ATTENTION_STATUS:
              return <TableUpperActionFilterContainer key={element}>
                <InputSelect
                  key={ALLOWED_FILTERS.ATTENTION_STATUS}
                  label="Estado"
                  name="state"
                  required={false}
                  onSelect={val => {
                    setAttentionStatus(val ? [parseInt(val)] : DEFAULT_ALL_SERVICE_STATES);
                  }}
                  value={attentionStatus}
                >
                  {Object.keys(ALLOWED_SERVICE_STATES_LABELS).map((element) => {
                    return <option key={element} value={element}>{(ALLOWED_SERVICE_STATES_LABELS as any)[element]}</option>
                  })}
                </InputSelect>
              </TableUpperActionFilterContainer>
            case ALLOWED_FILTERS.REGION:
              return <TableUpperActionFilterContainer key={element}>
                <InputSelect
                  key={ALLOWED_FILTERS.REGION}
                  label="Región"
                  data-testId="buscar-paciente-testid-region"
                  name="region"
                  required={false}
                  onSelect={val => {
                    setSelectedRegion(val);
                  }}
                  value={region}
                >
                  {
                    listProvinces.map((province) => {
                      return <option key={province.id} value={province.id} >{province.name}</option>
                    })
                  }
                </InputSelect>
              </TableUpperActionFilterContainer>
            case ALLOWED_FILTERS.TEXT:
              return <TableUpperActionFilterContainer key={element}>
                <Input
                  key={ALLOWED_FILTERS.TEXT}
                  label="Buscar paciente"
                  data-testId="buscar-paciente-testid-texto"
                  value={searchText}
                  icon={SearchIcon}
                  classNameWrapper={'h-[48px] max-w-48'}
                  onChange={(e: any) => {
                    setSearchText(e.target.value)
                  }}
                />
              </TableUpperActionFilterContainer>
            case ALLOWED_FILTERS.TEXT_ATTENTION:
              return <TableUpperActionFilterContainer key={element}>
                <Input
                  key={ALLOWED_FILTERS.TEXT_ATTENTION}
                  label="Buscar atenciones"
                  data-testId="buscar-paciente-testid-texto"
                  value={searchText}
                  icon={SearchIcon}
                  classNameWrapper={'h-[48px] max-w-48'}
                  onChange={(e: any) => {
                    setSearchText(e.target.value)
                  }}
                />
              </TableUpperActionFilterContainer>
          }
        })
      }
    </div>}
  </div>
  )
}