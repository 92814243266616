import { FC } from 'react'
import useUser from 'features/shared/hooks/useUser'

import Breadcrumbs from 'components/Breadcrumbs'
import Loader from 'components/Loader'

import { NotificationsCounter } from './components/NotificationsCounter'
import { NotificationGroup } from './components/NotificationCard/NotificationCardNew'
import IconEmpty from 'assets/icons/icon_empty.svg'
import SearchIcon from 'assets/icons/icon_search.svg'

import {
  Notifications,
  GetNotificationsResponse,
  useGetNotificationsSummaryTotal,
  GetNotificationsSummaryTotalResponseData,
  useGetNotifications,
  NotificationType,
} from './services'
import { ROLE } from 'global/constants/roles'
import React from 'react'
import ImageError from 'components/ImageError'
import { SelectDateRangeFunction } from 'features/ClinicHistory/ListPatients/Table/TableUpperActions.utils'
import { ALLOWED_FILTERS } from 'features/ClinicHistory/ListPatients/Table/TableUpperActions'
import "./NotificationsDashboard.css"
import moment from 'moment'
import { formatDate } from 'features/shared/utils/text/formatDate'
import { useNotificationsFiltersDashboard } from './hooks/use-notifications-filters-dashboard'
import InputSelect from 'components/InputSelect'
import { InputTextDebounced } from 'components/InputTextDebounced'
import { BREADCRUMB_ORIGIN, getBreadcrumbDetails } from 'features/shared/utils/getBreadcrumbDetails'
import { useLocation } from 'react-router-dom'

interface NotificationsDashboardHeaderProps {
  notificationsSummary: GetNotificationsSummaryTotalResponseData
  onTypeSelected: (type: NotificationType) => void
  selectedNotificationType?: string;
}

interface NotificationsDashboardBodyProps {
  notificationsPages: GetNotificationsResponse[]
}

interface NotificationsDashboardBodyGroupsProps {
  notificationsGroups: Notifications[]
  pageIndex: number
}

const NotificationsDashboardBodyGroups: FC<
  NotificationsDashboardBodyGroupsProps
> = ({ notificationsGroups, pageIndex }) => {
  return (
    <>
      {notificationsGroups?.length === 0 && pageIndex === 0 ? (
        <div className={'flex flex-row justify-center mt-6 mb-6'}>
          <ImageError className={'border-dashed border-[2px] rounded-[16px] min-h-[352px]	h-full  text-left text-caption '} image={IconEmpty}>
            <br />
            <br />
            <br />
            No hay notificaciones que mostrar, prueba cambiando los filtros.
          </ImageError>

        </div>
      ) : (
        notificationsGroups?.map((notificationsGroup, index) => (
          <NotificationGroup
            key={index}
            notificationsGroup={notificationsGroup}
            defaultOpen={pageIndex === 0 && index === 0}
          />
        ))
      )}
    </>
  )
}

const NotificationsDashboardBody: FC<NotificationsDashboardBodyProps> = ({
  notificationsPages,
}) => {

  return (
    <div className={'flex flex-col gap-6'}>
      {notificationsPages.map((notifications, index) => (
        <NotificationsDashboardBodyGroups
          key={index}
          notificationsGroups={notifications?.data?.data}
          pageIndex={index}
        />
      ))}
    </div>
  )
}


const NotificationsDashboardHeader: FC<NotificationsDashboardHeaderProps> = ({
  notificationsSummary,
  onTypeSelected,
  selectedNotificationType
}) => {
  const { userCurrent } = useUser()

  return (
    <div className={'flex flex-wrap gap-5'}>

      {ROLE.PATIENT === userCurrent?.roleSlug &&
        <React.Fragment>
          <NotificationsCounter
            variant={NotificationType.call}
            count={notificationsSummary?.calls}
            onClick={onTypeSelected}
            selected={selectedNotificationType === NotificationType.call}
          />
          <NotificationsCounter
            variant={NotificationType.queryWorkplan}
            count={notificationsSummary?.workplan || 0}
            onClick={onTypeSelected}
            selected={selectedNotificationType === NotificationType.queryWorkplan}
          />
          <NotificationsCounter
            variant={NotificationType.annotations}
            count={notificationsSummary?.annotations || 0}
            onClick={onTypeSelected}
            selected={selectedNotificationType === NotificationType.annotations}
          />
        </React.Fragment>

      }
      {ROLE.HEALTH_PROFESSIONAL === userCurrent?.roleSlug &&
        <React.Fragment>
          <NotificationsCounter
            variant={NotificationType.call}
            count={notificationsSummary?.calls}
            onClick={onTypeSelected}
            selected={selectedNotificationType === NotificationType.call}
          />
          <NotificationsCounter
            variant={NotificationType.queryWorkplan}
            count={notificationsSummary?.workplan || 0}
            onClick={onTypeSelected}
            selected={selectedNotificationType === NotificationType.queryWorkplan}
          />
          <NotificationsCounter
            variant={NotificationType.annotations}
            count={notificationsSummary?.annotations || 0}
            onClick={onTypeSelected}
            selected={selectedNotificationType === NotificationType.annotations}
          />
        </React.Fragment>

      }
      {ROLE.SERVICE_ADMIN === userCurrent?.roleSlug &&
        <React.Fragment>
          <NotificationsCounter
            variant={NotificationType.call}
            count={notificationsSummary?.calls}
            onClick={onTypeSelected}
            selected={selectedNotificationType === NotificationType.call}
          />
          <NotificationsCounter
            variant={NotificationType.queryWorkplan}
            count={notificationsSummary?.workplan || 0}
            onClick={onTypeSelected}
            selected={selectedNotificationType === NotificationType.queryWorkplan}
          />
          <NotificationsCounter
            variant={NotificationType.report}
            count={notificationsSummary?.devices}
            onClick={onTypeSelected}
            selected={selectedNotificationType === NotificationType.report}
          />
        </React.Fragment>
      }

      <div className={'border-solid border-r-[1px] grow-1 border-[#ADADAD]'} />
      <NotificationsCounter
        variant={'attended' as any}
        count={0}
        onClick={onTypeSelected}
        selected={selectedNotificationType === 'attended' as any}
      />
    </div>
  )
}

const ALLOWED_FILTERS_BY_ROLE_BY_TAB = {
  [ROLE.PATIENT]: {
    [NotificationType.call]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE
    ],
    [NotificationType.queryWorkplan]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE
    ],
    [NotificationType.annotations]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE
    ],
    [NotificationType.attended]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE
    ],
    "DEFAULT": [
      ALLOWED_FILTERS.NOTIFICATION_DATE
    ]
  },
  [ROLE.HEALTH_PROFESSIONAL]: {
    [NotificationType.call]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    [NotificationType.queryWorkplan]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    [NotificationType.annotations]: [
      ALLOWED_FILTERS.NOTIFICATION_TYPE,
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    [NotificationType.attended]: [
      ALLOWED_FILTERS.NOTIFICATION_TYPE,
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    "DEFAULT": [
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ]
  },
  [ROLE.SERVICE_ADMIN]: {
    [NotificationType.call]: [
      ALLOWED_FILTERS.NOTIFICATION_TYPE,
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    [NotificationType.queryWorkplan]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    [NotificationType.report]: [
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    [NotificationType.attended]: [
      ALLOWED_FILTERS.NOTIFICATION_TYPE,
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ],
    "DEFAULT": [
      ALLOWED_FILTERS.NOTIFICATION_DATE,
      ALLOWED_FILTERS.NOTIFICATION_PATIENT,
    ]
  }
}

const minDate = moment(new Date()).subtract(2, "years").toDate();
const maxDate = moment(new Date()).add(2, "day").toDate();

const NotificationsDashboard = () => {
  const { userCurrent } = useUser()
  const location = useLocation();

  const {
    filterData,
    setFilterData
  } = useNotificationsFiltersDashboard()

  const attendedNotifications = filterData?.notificationType === NotificationType.attended as any;
  const {
    getNotificationsResponse,
    getNotificationsIsLoading,
    getNotificationsIsFetching,
    hasMoreNotifications,
    fetchNextNotificationsPage,
  } = useGetNotifications({
    role: userCurrent?.roleSlug, ...{
      from: filterData?.dateRange?.startDateRange ? formatDate(filterData?.dateRange?.startDateRange, "YYYY-MM-DD") : undefined,
      to: filterData?.dateRange?.endDateRange ? formatDate(filterData?.dateRange?.endDateRange, "YYYY-MM-DD") : undefined,
      type: attendedNotifications ? undefined : filterData?.notificationType,
      attended: attendedNotifications,
      patientTextSearch: filterData?.patientTextSearch,
      deviceSource: filterData?.deviceSource || undefined
    }
  })

  const { notificationsSummaryTotal, notificationsSummaryTotalIsLoading } =
    useGetNotificationsSummaryTotal({ role: userCurrent?.roleSlug })

  if (
    notificationsSummaryTotalIsLoading ||
    getNotificationsIsLoading ||
    !userCurrent
  ) {
    return <Loader />
  }

  let roleFilters = ALLOWED_FILTERS_BY_ROLE_BY_TAB[userCurrent?.roleSlug];
  let filtersByTab: ALLOWED_FILTERS[] = [];
  if (roleFilters) {
    if ((roleFilters as any)[filterData?.notificationType || ""]) {
      filtersByTab = (roleFilters as any)[filterData?.notificationType || ""]
    } else {
      filtersByTab = (roleFilters as any)["DEFAULT"]
    }
  }
  let filters = filtersByTab || [];

  const filledElements = filters.length < 5 ? Array.from({ length: 5 - filters.length }, () => null) : [];
  const { resultingElements } = getBreadcrumbDetails({
    location: location as any,
    from: BREADCRUMB_ORIGIN.NOTIFICATIONS,
    additionalData: { type: filterData?.notificationType }
  })

  return (
    <div>
      {getNotificationsIsFetching ? <Loader /> : null}
      <Breadcrumbs
        name={''}
        currentPathName={""}
        elements={resultingElements}
      />

      <div className={'flex flex-col gap-6 mt-6'}>
        {notificationsSummaryTotal ? (
          <NotificationsDashboardHeader
            notificationsSummary={notificationsSummaryTotal.data}
            selectedNotificationType={filterData?.notificationType}
            onTypeSelected={(selectedType) => {
              setFilterData({
                ...filterData,
                notificationType: selectedType,
                dateRange: undefined,
                patientTextSearch: undefined,
                deviceSource: undefined
              });
            }}
          />
        ) : null}

        <div className={'flex flex-col bg-white rounded-2xl p-4 gap-6'}>
          <div className={'flex flex-col gap-8'}>
            <div className={'sm:flex-row sm:grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-5'} id="notifications-dashboard-top">
              {
                filledElements.map((element, index) => <div key={`filler-` + index}></div>)
              }
              {
                filters.map((element: any) => {
                  if (element === ALLOWED_FILTERS.NOTIFICATION_PATIENT) {
                    return <div key={ALLOWED_FILTERS.NOTIFICATION_PATIENT}>
                      <InputTextDebounced
                        inputProps={{
                          label: "Buscar paciente",
                          icon: SearchIcon,
                        }}
                        defaultValue={filterData?.patientTextSearch}
                        onDebouncedValue={(value) => {
                          setFilterData({
                            ...filterData,
                            patientTextSearch: value
                          })
                        }}
                        debounceMillis={500}
                      />
                    </div>
                  }

                  if (element === ALLOWED_FILTERS.NOTIFICATION_TYPE) {
                    return <div key={ALLOWED_FILTERS.NOTIFICATION_TYPE}>
                      <InputSelect
                        label="Tipo de notificación"
                        name="deviceSource"
                        required
                        onSelect={val => {
                          setFilterData({
                            ...filterData,
                            deviceSource: val
                          })
                        }}
                        value={filterData?.deviceSource}
                      >
                        <option value={"platform"}>Plataforma</option>
                        <option value={"alexa"}>Alexa</option>
                      </InputSelect>
                    </div>
                  }

                  if (element === ALLOWED_FILTERS.NOTIFICATION_DATE) {
                    return <div key={ALLOWED_FILTERS.NOTIFICATION_DATE}>
                      <SelectDateRangeFunction
                        key={ALLOWED_FILTERS.NOTIFICATION_DATE}
                        data-testId="buscar-notificaciones-testid-fecha-salida"
                        label="Seleccionar fecha"
                        id="notificationsDate"
                        minDate={minDate}
                        maxDate={maxDate}
                        initialDateRange={filterData?.dateRange}
                        onDateSelected={(dateRange) => {
                          if (!dateRange) {
                            return;
                          }
                          setFilterData({
                            ...filterData,
                            dateRange
                          })
                        }} />
                    </div>
                  }

                  return null;
                })
              }
            </div>
          </div>

          {getNotificationsResponse ? (
            <NotificationsDashboardBody
              notificationsPages={getNotificationsResponse.pages}
            />
          ) : null}

          {hasMoreNotifications ? (
            <div className={'flex flex-row justify-center'}>
              <button
                className={'text-[#00B1CC] font-semibold'}
                onClick={() => fetchNextNotificationsPage()}
              >
                Ver más
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default NotificationsDashboard

