import fetcher from 'features/shared/utils/fetcher'
import { NotificationEmergencyPost, NotificationCallDoctorPost, NotificationCallPatientPost } from '../types/notification'

export async function createNotificationEmergency(json: NotificationEmergencyPost) {
  const { data } = await fetcher.post(`/notification/patient/emergency-call`, json)
  return data
}

export async function createNotificationCallDoctor(json: NotificationCallDoctorPost) {
  const { data } = await fetcher.post(`/notification/patient/doctor-call`, json)
  return data
}

export async function createNotificationCallPatient(json: NotificationCallPatientPost) {
  const data = await fetcher.post(`/notification/specialist/patient-call`, json)
  return data
}

export interface IGeneralNotificationPayload {
  json: any, fromRole: string, toRole: string, type: string
}

export async function createGeneralNotification(payload: {
  json: any, fromRole: string, toRole: string, type: string
}) {
  const {
    json,
    fromRole,
    toRole,
    type,
  } = payload;
  const data = await fetcher.post(`/notification/general/${fromRole}/${toRole}/${type}/add`, json)
  return data
}