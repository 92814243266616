import EditIcon from 'assets/icons/icon_action_pencil.svg';
import HistoryIcon from 'assets/icons/medic_exam.svg';
import NewAttentionIcon from 'assets/icons/medic_rood.svg';
import AmbulanceIcon from 'assets/icons/icon_ambulance.svg';
import AttentionDetailsIcon from 'assets/icons/icon_attention_details.svg'

import { ToolTipWrapper } from "../ToolTip";
import { formatDate } from "features/shared/utils/text/formatDate";
import { calculateActionRoutes } from 'features/shared/utils/calculateActionRoutes';
import { useLocation } from 'react-router-dom';
import Chip from 'components/Chip';
import './TableBody.snippets.css'
import { AVAILABLE_ACTIONS } from 'global/constants/enums';

export const DocumentNumberSnippet = ({
  documentNumber,
}: {
  documentNumber: string
}) => {
  return <td
    className="px-4 py-2 whitespace-nowrap"
  >
    <div className="text-body2">{documentNumber}</div>
  </td>
}

export const PatientFullNameSnippet = ({
  name,
  lastName
}: {
  name?: string;
  lastName?: string
}) => {
  return (
    <td
      className="px-4 py-2 "
    >
      <div className="text-body2 flex items-center gap-2">
        {false ? (
          <img
            src={''}
            alt={''}
            className="rounded-full w-10 h-10"
          />
        ) : (
          <div className="rounded-full bg-[#DFF5FF] text-[#008EA3] min-w-[1.5rem] min-h-[1.5rem] w-6 h-6 flex justify-center items-center text-caption">
            {' '}
            {name?.charAt(0)}
            {lastName?.charAt(0)}{' '}
          </div>
        )}
        {name} {lastName}
      </div>
    </td>)
}

export const PatientPhoneSnippet = ({
  phone,
}: {
  phone?: string;
}) => {
  return (
    <td
      className="px-4 py-2 whitespace-nowrap"
    >
      <div className="text-body2">{phone}</div>
    </td>)
}

export const PatientAddressSnippet = ({ address }: {
  address: string
}) => {
  return (
    <td
      className="px-4 py-2 whitespace-nowrap"
    >
      <div className="text-body2">{address}</div>
    </td>)
}

export const ViewLocationSnippet = ({
  lat,
  lng
}: {
  lat: string,
  lng: string
}) => {
  return (<td className="px-4 py-2 whitespace-nowrap">
    <a href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`} target="_blank" rel="noopener noreferrer">
      <button
        className="border-nonde text-body2 p-0 text-primary-main"
      >
        Ver ubicación
      </button>
    </a>
  </td>)
}

export const PatientAdmissionDateSnippet = ({
  admissionDate
}: {
  admissionDate?: string
}) => {
  return (
    <td
      className="px-4 py-2 whitespace-nowrap"
    >
      <div className="text-body2">
        {admissionDate
          ? formatDate(admissionDate)
          : '-'}
      </div>
    </td>)
}

export const PatientClinicHistoryNumberSnippet = ({ nhc }: { nhc: number }) => {
  return <td
    className="px-4 py-2 whitespace-nowrap"
  >
    <div className="text-body2">
      {nhc || '-'}
    </div>
  </td>
}

export const RegionNameSnippet = ({ region }: { region: string }) => {
  return <td
    className="px-4 py-2 whitespace-nowrap"
  >
    <div className="text-body2">
      {region || '-'}
    </div>
  </td>
}

export const AttentionDateSnippet = ({ history, patientId, attentionDate }: { history: any, patientId: number, attentionDate: string }) => {
  return <td
    className="px-4 py-2 whitespace-nowrap"
  >
    <div className="text-body2">
      {attentionDate
        ? formatDate(attentionDate)
        : '-'}
    </div>
  </td>
}

export const DiagnosisSnippet = ({ diagnosticText }: { diagnosticText: string }) => {
  return <td
    className="px-4 py-2 whitespace-wrap"
  >
    <div className="text-body2">
      {diagnosticText || ""}
    </div>
  </td>
}

export const HospitalizationDaysSnippet = ({ totalDays, totalExpectedDays }: { totalDays: string, totalExpectedDays: string }) => {
  return <td
    className="px-4 py-2 whitespace-nowrap"
  >
    <div className="text-body2">
      {totalDays} de {totalExpectedDays || "--"}
    </div>
  </td>
}

export const AttentionNumberSnippet = ({ attentionNumber }: { attentionNumber: string }) => {
  return <td
    className="px-4 py-2 whitespace-nowrap"
  >
    <div className="text-body2">
      {attentionNumber}
    </div>
  </td>
}

export const AttentionStatusSnippet = ({ attentionStatus }: { attentionStatus: string }) => {
  let labelToChoose:string = "";
  let variant: string = "";
  if(attentionStatus.match(/pendiente/i)){
    labelToChoose = 'Pendiente'
    variant = "pending justify-center items-center"
  } else if(attentionStatus.match(/atenc/i)){
    labelToChoose = 'En atención'
    variant = "in-progress justify-center items-center"
  } else if(attentionStatus.match(/dado de alta/i)){
    labelToChoose = 'Atendido'
    variant = "completed justify-center items-center"
  }

  return <td
    className="px-4 py-2 whitespace-nowrap"
  >
    <div className="text-body2">
      <Chip onClose={() => {}} className={variant} label={labelToChoose}/>
    </div>
  </td>
}

export const EvaluatorDoctorFullNameSnippet = ({ evaluatorDoctorFullName }: { evaluatorDoctorFullName: string }) => {
  return <td
    className="px-4 py-2 whitespace-nowrap"
  >
    <div className="text-body2">
      {evaluatorDoctorFullName}
    </div>
  </td>
}

const ActionSnippetBody = ({
  key,
  icon,
  onClick,
  text
}: {
  key: string,
  icon: string,
  onClick: () => void,
  text: string
}) => {
  return <ToolTipWrapper
    key={key} text={text}>
    <img key={key} src={icon} alt={`icon ${text}`} className='mr-2 w-[1.5em] h-[1.5em] cursor-pointer mr-3' onClick={onClick} /></ToolTipWrapper>
}

export const ActionsSnippet = ({ history, patientId, potentialActions, serviceId }: { history: any, patientId: number, potentialActions: AVAILABLE_ACTIONS[], serviceId?: number; }) => {
  const location = useLocation()
  return <td
    className="px-4 py-2 whitespace-wrap"
  >
    <div className="text-body2 flex-row flex">
      {
        potentialActions.map((element) => {
          switch (element) {
            case AVAILABLE_ACTIONS.ATTENTIONS_HISTORY:
              return <ActionSnippetBody
                key={`${element}`}
                icon={HistoryIcon}
                text="Historial de atenciones"
                onClick={() => {
                  const finalRoute = calculateActionRoutes({
                    action: AVAILABLE_ACTIONS.ATTENTIONS_HISTORY,
                    patientId: patientId.toString(),
                    currentLocation: location
                  })
                  history.push(
                    finalRoute
                  )
                }} />
            case AVAILABLE_ACTIONS.EDIT_ATTENTION:
              return <ActionSnippetBody
                key={`${element}`}
                icon={EditIcon}
                text="Editar atención"
                onClick={() => {
                  const finalRoute = calculateActionRoutes({
                    action: AVAILABLE_ACTIONS.EDIT_ATTENTION,
                    patientId: patientId.toString(),
                    currentLocation: location
                  })
                  history.push(
                    finalRoute
                  )
                }} />
            case AVAILABLE_ACTIONS.EDIT_PATIENT:
              return <ActionSnippetBody
                key={`${element}`}
                icon={EditIcon}
                text="Editar paciente"
                onClick={() => {
                  const finalRoute = calculateActionRoutes({
                    action: AVAILABLE_ACTIONS.EDIT_PATIENT,
                    patientId: patientId.toString(),
                    currentLocation: location
                  })
                  history.push(
                    finalRoute
                  )
                }} />
            case AVAILABLE_ACTIONS.NEW_ATTENTION:
              return <ActionSnippetBody
                key={`${element}`}
                icon={NewAttentionIcon}
                text="Agregar atención"
                onClick={() => {
                  const finalRoute = calculateActionRoutes({
                    action: AVAILABLE_ACTIONS.NEW_ATTENTION,
                    patientId: patientId.toString(),
                    currentLocation: location
                  })
                  history.push(
                    finalRoute
                  )
                }} />
            case AVAILABLE_ACTIONS.ATTENTION_DETAILS:
              return <ActionSnippetBody
                key={`${element}`}
                icon={AttentionDetailsIcon}
                text="Detalle atención"
                onClick={() => {
                  const finalRoute = calculateActionRoutes({
                    action: AVAILABLE_ACTIONS.ATTENTION_DETAILS,
                    patientId: patientId.toString(),
                    currentLocation: location,
                    serviceId: serviceId
                  })
                  history.push(
                    finalRoute
                  )
                }} />
            case AVAILABLE_ACTIONS.VISITS_HISTORICAL:
              return <ActionSnippetBody
                key={`${element}`}
                icon={AmbulanceIcon}
                text="Historial de visitas"
                onClick={() => {
                }} />
            default:
              return "?"
          }
        })
      }
    </div>
  </td>
}