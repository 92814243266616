import {
  useState,
  useCallback,
  useEffect,
  createContext,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react'

import IconCalendar from 'assets/icons/icon_calendar.svg'
import LabelForm from 'components/LabelForm'
import WorkplanPatientCard from 'components/WorkplanPatientCard'

import { PlanTodayMedicamentsModel, usePostIntakeMedication } from '../hooks/userPlanToday'
import MedicineModal from '../components/WorkPlanList/MedicineModal'
import WhyNotTakeMedicineModal from '../components/WorkPlanList/WhyNotTakeMedicineModal'
import { onAlert } from 'components/Alert'
import { useCreateAnnotation } from '../hooks/useAnnotation'
import useUser from 'features/shared/hooks/useUser';
import LoaderMini from 'components/LoaderMini'

interface WorkPlanListProps {
  data: PlanTodayMedicamentsModel[],
  serviceId?: any,
  planId?: any
  refetchPlan: () => any,
  patientId?: number,
  listIntakeMedications: PlanTodayMedicamentsModel[]
}

export const WorkListContext = createContext<{
  medicineToCheckList: PlanTodayMedicamentsModel[]
  medicineTakenList: PlanTodayMedicamentsModel[]
  medicineNotTakenList: PlanTodayMedicamentsModel[]
  reasonNotTakeMyMedicine: any
  commentNotTakeMyMedicine: string
  medicineNotTakenSelected: PlanTodayMedicamentsModel | null
  setMedicineNotTakenSelected: Dispatch<SetStateAction<any>>
  setReasonNotTakeMyMedicine: Dispatch<SetStateAction<any>>
  setCommentNotTakeMyMedicine: Dispatch<SetStateAction<string>>
  setShowWhyNotTakeMedicineModal: Dispatch<SetStateAction<boolean>>
  setMedicineTakenList: Dispatch<SetStateAction<PlanTodayMedicamentsModel[]>>
  setMedicineNotTakenList: Dispatch<SetStateAction<PlanTodayMedicamentsModel[]>>
  onMedicineNotCheckedClickNo: (dataNotMedicine: any) => void
  onMedicineNotCheckedClickYes: (medicine: PlanTodayMedicamentsModel) => void
  onCloseMedicineModal: () => void
  onCloseWhyNotTakeMedicineModal: () => void
}>({
  medicineToCheckList: [],
  medicineTakenList: [],
  medicineNotTakenList: [],
  reasonNotTakeMyMedicine: null,
  commentNotTakeMyMedicine: '',
  medicineNotTakenSelected: null,
  setMedicineNotTakenSelected: () => { },
  setReasonNotTakeMyMedicine: () => { },
  setCommentNotTakeMyMedicine: () => { },
  setShowWhyNotTakeMedicineModal: () => { },
  setMedicineTakenList: () => { },
  setMedicineNotTakenList: () => { },
  onMedicineNotCheckedClickNo: () => { },
  onMedicineNotCheckedClickYes: () => { },
  onCloseMedicineModal: () => { },
  onCloseWhyNotTakeMedicineModal: () => { },
})

const WorkPlanList: React.FC<WorkPlanListProps> = ({ data, listIntakeMedications, serviceId, planId, patientId, refetchPlan }) => {
  const { mutateAsync: postIntakeMedication, isLoading: loadingposttIntakeMedication } = usePostIntakeMedication();
  const { mutateAsync: postCreateAnnotation, isLoading: loadingpostCreateAnnotation } = useCreateAnnotation()
  const { userCurrent } = useUser();

  const [showWhyNotTakeMedicineModal, setShowWhyNotTakeMedicineModal] =
    useState(false)
  const [showMedicineModal, setShowMedicineModal] = useState(false)
  const [reasonNotTakeMyMedicine, setReasonNotTakeMyMedicine] = useState(null)
  const [commentNotTakeMyMedicine, setCommentNotTakeMyMedicine] = useState('')
  const [medicineNotTakenSelected, setMedicineNotTakenSelected] =
    useState<PlanTodayMedicamentsModel | null>(null)
  const [medicineToCheckList, setMedicineToCheckList] = useState<
    PlanTodayMedicamentsModel[]
  >([])
  const [medicineTakenList, setMedicineTakenList] = useState<
    PlanTodayMedicamentsModel[]
  >([])
  const [medicineNotTakenList, setMedicineNotTakenList] = useState<
    PlanTodayMedicamentsModel[]
  >([])

  const onCloseMedicineModal = useCallback(() => {
    setShowMedicineModal(false)
  }, [setShowMedicineModal])

  const onCloseWhyNotTakeMedicineModal = useCallback(() => {
    setShowWhyNotTakeMedicineModal(false)
  }, [setShowWhyNotTakeMedicineModal])

  const onMedicineNotCheckedClickYes = async (
    medicine: PlanTodayMedicamentsModel
  ) => {
    const obj = {
      serviceId: serviceId,
      body: {
        "supplyId": medicine.supplyId,
        "startTime": medicine.startTime,
        "takeMedication": true,
        "reason": null,
        "comment": null,
      }
    }
    const response = await postIntakeMedication(obj);
    const objAnnotation = {

      planId,
      userId: userCurrent?.id,
      message: 'El paciente tomó la medicina ' + medicine?.supplyName + ' del ' + medicine?.day + ' a las ' + medicine?.startTime

    }
    if (Number.isInteger(response?.data)) {
      onAlert.success('Respuesta registrada');
      const responseAnnotation = await postCreateAnnotation(objAnnotation);
      
      if (Number.isInteger(responseAnnotation?.data)) {
        
        onAlert.success('Respuesta registrada')
      } else {
        console.log(responseAnnotation);
      }

    } else {

      if (response?.data) {
        onAlert.error(response?.data)
      } else {
        onAlert.error(response)
      }
    }
    refetchPlan();
  }

  const onMedicineNotCheckedClickNo = async () => {

    const obj = {
      serviceId: serviceId,
      body: {
        "supplyId": medicineNotTakenSelected?.supplyId,
        "startTime": medicineNotTakenSelected?.startTime,
        "takeMedication": false,
        "reason": reasonNotTakeMyMedicine,
        "comment": commentNotTakeMyMedicine,
      }
    }
    const response = await postIntakeMedication(obj);
    const objAnnotation = {

      planId,
      userId: userCurrent?.id,
      message: 'El paciente no tomó la medicina ' + medicineNotTakenSelected?.supplyName + ' del ' + medicineNotTakenSelected?.day + ' a las ' + medicineNotTakenSelected?.startTime

    }
    if (Number.isInteger(response?.data)) {
      onAlert.success('Respuesta registrada')
      setShowWhyNotTakeMedicineModal(false)
      setMedicineNotTakenSelected(null)
      const responseAnnotation = await postCreateAnnotation(objAnnotation);
      if (Number.isInteger(responseAnnotation?.data)) {
        onAlert.success('Respuesta registrada')

      } else {
        console.log(responseAnnotation);
      }
    } else {
      setShowWhyNotTakeMedicineModal(false)

      if (response?.data) {
        onAlert.error(response?.data)
      } else {
        onAlert.error(response)
      }
    }
    refetchPlan();
  }

  useEffect(() => {
    setMedicineToCheckList(listIntakeMedications)
  }, [listIntakeMedications])

  useEffect(() => {
    /*     console.log('medicineToCheckList', medicineToCheckList)
     *//*     if (constants.ENV_STAGE === 'dev') {
   */
    if (medicineToCheckList.length === 0) {
/*       console.log('closing medicine modal')
 */      setShowMedicineModal(false)
    } else {
      setShowMedicineModal(true)
    }
    /*     }
     */
  }, [medicineToCheckList])

  const contextValue = useMemo(
    () => ({
      medicineToCheckList,
      medicineTakenList,
      medicineNotTakenList,
      reasonNotTakeMyMedicine,
      commentNotTakeMyMedicine,
      medicineNotTakenSelected,
      setMedicineNotTakenSelected,
      setReasonNotTakeMyMedicine,
      setCommentNotTakeMyMedicine,
      setShowWhyNotTakeMedicineModal,
      setMedicineTakenList,
      setMedicineNotTakenList,
      onMedicineNotCheckedClickNo,
      onMedicineNotCheckedClickYes,
      onCloseMedicineModal,
      onCloseWhyNotTakeMedicineModal,
    }),
    [
      commentNotTakeMyMedicine,
      medicineNotTakenList,
      medicineNotTakenSelected,
      setMedicineNotTakenSelected,
      medicineTakenList,
      medicineToCheckList,
      onCloseMedicineModal,
      onCloseWhyNotTakeMedicineModal,
      onMedicineNotCheckedClickNo,
      onMedicineNotCheckedClickYes,
      reasonNotTakeMyMedicine,
    ]
  )
  return (
    <WorkListContext.Provider value={contextValue}>
      <div className="relative">
        <div className="bg-white shadow-md border-solid border-[1px] border-grey-200 rounded-[16px] p-[16px] row-span-1 col-span-full">
          <div className="flex justify-between items-center mb-4">
            <LabelForm
              icon={IconCalendar}
              label="Toma de medicamentos para hoy"
              className="text-[14px] leading-[20px] font-medium"
            />
          </div>
          <div className=" rounded-lg  w-full sm:h-[476px]  overflow-y-scroll scrollbar">
            {data?.length > 0 ? (
              data.map((el: PlanTodayMedicamentsModel, index: number) => (
                <WorkplanPatientCard
                  key={index}
                  commentary={el.startTime}
                  description={el.description}
                  typeColor={el.supplyId}
                />
              ))
            ) : (
              <></>
            )}
            
          </div>
          {loadingposttIntakeMedication || loadingpostCreateAnnotation &&
              <LoaderMini className={'z-[9999999] top-[0px] z'} />
            }
        </div>
        {showMedicineModal && <MedicineModal />}
        {showWhyNotTakeMedicineModal && <WhyNotTakeMedicineModal />}
      </div>
    </WorkListContext.Provider>
  )
}


export default WorkPlanList

