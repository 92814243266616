import {
  format as formatDate,
  differenceInMinutes,
  formatDistanceToNow,
  isToday,
} from 'date-fns'
import { es } from 'date-fns/locale'

export const getName = (name: string) => {
  return name.split(' ')[0]
}

export const getLastname = (name: string) => {
  const patientName = name.split(' ')
  if (patientName.length === 2) {
    return patientName[1]
  }
  if (patientName.length > 2) {
    return patientName[2]
  }
  return ''
}

export const getHeaderDate = (createdAt: string): string => {
  const notificationDate = new Date(createdAt)
  const diffInMin = differenceInMinutes(notificationDate, new Date())
  if (Math.abs(diffInMin) < 45) {
    const formattedDistance = formatDistanceToNow(notificationDate, {
      locale: es,
    })
    return 'Hace ' + formattedDistance.replace('minutos', 'min')
  }
  return formatDate(notificationDate, 'dd/MM/yyyy HH:mm a')
}

export const getFooterDate = (createdAt: string): string => {
  return formatDate(new Date(createdAt), 'dd/MM/yyyy HH:mm a')
}

export const getAccordionTitleDate = (date: string): string => {
  return formatDate(new Date(date + 'T00:00:00'), "EEEE dd 'de' MMMM", {
    locale: es,
  })
}

export const checkIsToday = (date: string): boolean => {
  return (isToday(new Date(date + 'T00:00:00')))
}

export const getDateFromQueryParam = (queryParamName: string, query: any) => {
  const date = query[queryParamName]
  return date ? new Date(date + 'T00:00:00') : undefined
}

export const getAccordionTitle = (date: string) => {
  if (checkIsToday(date)) {
    return 'Hoy'
  }
  return getAccordionTitleDate(date)
}

export const getAtHeaderDate = (dateAt: string) => {
  const dateFormatted = getHeaderDate(dateAt || "");
  return `${dateFormatted.match(/hace/i) ? `${dateFormatted.toLowerCase()}` : `el ${dateFormatted}`}`;
}