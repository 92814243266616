import LabelForm from "components/LabelForm"
import PressureIcon from 'assets/icons/icon_pressure_black.svg';
import { PatientPlanDevice } from "features/Notifications/services";

export const DeviceElement = ({
    record
}: {
    record: PatientPlanDevice
}) => {
    return (
        <div className="box-border flex flex-col items-start bg-white border border-[#E2E2E2] rounded-[16px] p-4 h-full">
            <div className="flex flex-row w-full flex-1">
                <div className="flex items-center flex-1">
                    <span className="font-poppins font-semibold text-[20px] text-[#141414]">
                        {record.product}
                    </span>
                </div>
                <div className="flex items-center justify-center">
                    <div className="flex flex-row justify-center items-center bg-[#008EA3] rounded-[8px] pl-3 pr-3">
                        <span className="font-poppins font-normal text-[16px] text-white pt-1 pb-1">
                            Activo
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center bg-[#F6F6F6] rounded-[16px] w-full p-2 mt-2">
                <span className="font-poppins font-normal text-[12px] leading-[16px] flex items-center text-[#585A5B]">Código de producto:</span>
                <span className="font-poppins font-normal text-[16px] text-[#141414]">{record.code}</span>
            </div>
        </div>
    )
}

export const AssignedDevices = ({
    devices
}: {
    devices: PatientPlanDevice[]
}) => {
    return <div className="bg-white shadow-md border-solid border-[1px] border-grey-200 rounded-[16px] p-[16px] row-span-1 col-span-full">
        <div className="flex justify-between items-center mb-4">
            <LabelForm
                icon={PressureIcon}
                label="Dispositivos médicos"
                className="text-[14px] leading-[20px] font-medium"
            />
        </div>
        {
            !devices?.length ? (
                <div>El paciente no tiene dispositivos asignados.</div>
            ) : null
        }
        <div className="grid lg:grid-cols-2 gap-4 mt-[24px]">
            {
                devices.map(element => <DeviceElement record={element} key={`${element.deviceId}`} />)
            }
        </div>
    </div>
}