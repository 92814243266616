import IconFile from 'assets/icons/icon_file.svg'
import LabelForm from 'components/LabelForm'
import IconAdd from 'assets/icons/icon_add.svg'
import React, { useState } from 'react'
import ScrollToBottom from 'react-scroll-to-bottom'
import { useCreateAnnotation } from '../../hooks/useAnnotation'
import Moment from 'moment'
import { useQueryClient } from 'react-query'
import useUser from 'features/shared/hooks/useUser'
import { PlanTodayAnotationsModel } from 'features/PatientDashboard/hooks/userPlanToday'
import { Button } from 'amazon-chime-sdk-component-library-react'
import { UserInlinedAnnotationsCard } from 'components/AnnotationsCard/userInlined'
import { MessageModal } from './MessageModal'
import { PatientPlanAssignedPersonnel } from 'features/Notifications/services'
import { useCreateGeneralNotification } from 'features/ClinicHistory/hooks/useNotification'
import { ROLE } from 'global/constants/roles'
import { EventType } from 'global/constants/eventType'

interface Props {
  className?: string
  planId?: any
  patientId?: number
  data: PlanTodayAnotationsModel[]
  attendingSpecialist?: PatientPlanAssignedPersonnel
}

const Annotation: React.FC<Props> = ({ planId, className, patientId, data, attendingSpecialist }) => {
  const queryClient = useQueryClient()
  const { mutate: createAnnotation, isLoading } = useCreateAnnotation()
  const { userCurrent } = useUser()
  const [showModal, setShowModal] = useState(false)
  const { mutateAsync: createCustomNotification } = useCreateGeneralNotification()

  const notifyNewMessage = async ({ noteId }: { noteId: number }) => {
    try {
      if (userCurrent?.roleSlug === ROLE.PATIENT) {
        await createCustomNotification({
          json: {
            noteId,
          },
          fromRole: ROLE.PATIENT,
          toRole: ROLE.HEALTH_PROFESSIONAL,
          type: EventType.NOTIFICATION_TYPE_NOTE_PATIENT,
        })
      } else {
        console.log("Se debe implementar el envio con este rol.");
      }
    } catch (e) {
      console.log({
        e,
      })
    }
  }

  const handleConversations = async (newMessage: string) => {
    if (newMessage) {
      createAnnotation(
        {
          planId,
          userId: userCurrent?.id,
          message: newMessage,
        },
        {
          onSuccess: async (data: any) => {
            queryClient.invalidateQueries('planToday')
            notifyNewMessage({
              noteId: data?.data?.id,
            })
          },
          onSettled: () => {
            setShowModal(false)
          },
        }
      )
    }
  }

  return (
    <div className={className}>
      <div className="bg-white shadow-md border-solid border-[1px] border-grey-200 rounded-[16px] p-[16px] row-span-1 col-span-full">
        <div className="flex justify-between items-center mb-4">
          <LabelForm
            icon={IconFile}
            label="Anotaciones del paciente"
            className='text-[14px] leading-[20px] font-medium'
          />
        </div>
        <div className="flex flex-col">
          <div
            className="flex justify-between items-center mb-4"
            style={{
              height: '60vh',
            }}
          >
            <ScrollToBottom
              followButtonClassName="ScrollToBottom__btn"
              scrollViewClassName="overflow-y-scroll scrollbar"
              className="bg-white rounded-lg  w-full h-full"
            >
              <div data-testid="annotationList" className="pr-[16px]">
                {!data?.length && <div>El paciente no tiene anotaciones.</div>}
                {data?.map((conversationAnnotation: PlanTodayAnotationsModel, i) => {
                  const viewerIsSender = conversationAnnotation?.userId === patientId
                  const extraTags = []
                  const isFromAlexa = conversationAnnotation.sender === 'alexa'
                  if (isFromAlexa) {
                    extraTags.push({
                      name: 'Alexa',
                      className: `bg-[#DFF5FF] rounded-[8px] pl-2 pr-2 pt-1 pb-1 font-poppins font-medium text-[12px] text-[#008EA3]`,
                    })
                  }
                  return (
                    <div key={i} className="pb-4">
                      <UserInlinedAnnotationsCard
                        commentary={
                          conversationAnnotation &&
                          `Mensaje (${Moment(conversationAnnotation?.createdAt).format('DD/MM/YYYY, HH:mm:ss')})`
                        }
                        description={conversationAnnotation?.message}
                        className={`${viewerIsSender ? 'bg-[#F6F6F6] mb-2' : 'bg-[#DEF1FF] mb-2'}  `}
                        emitter={conversationAnnotation.name}
                        emitterRole={
                          conversationAnnotation.sender === 'patient'
                            ? 'Paciente'
                            : conversationAnnotation.speciality || conversationAnnotation.sender || ''
                        }
                        viewerIsSender={viewerIsSender}
                        extraTags={extraTags}
                      />
                    </div>
                  )
                })}
              </div>
            </ScrollToBottom>
          </div>
          <div className="flex justify-end rounded-lg mt-4">
            <Button
              className="bg-primary-main text-white"
              icon={<img src={IconAdd} alt="icon" className="w-[24px] h-[24px]" />}
              onClick={() => {
                setShowModal(true)
              }}
              label="Nuevo mensaje"
            />
          </div>
        </div>
      </div>
      <MessageModal
        onClose={() => setShowModal(false)}
        title={'Nuevo Mensaje'}
        withDivider={true}
        showModal={showModal}
        onSubmit={(newMessage: string) => {
          handleConversations(newMessage)
        }}
        sendToElement={{
          name: attendingSpecialist?.name || '',
          lastname: attendingSpecialist?.lastname || '',
          specialty: attendingSpecialist?.specialty || '',
        }}
        disabled={isLoading}
        maxLength={500}
      />
    </div>
  )
}

export default Annotation
