import { HEADER_ELEMENT_KEYS, HEADER_SORT_ORDER, TABLE_HEADER_LABELS } from "../index.utils"


export const TableHeadElement = ({
  allowedFields
}:{
  allowedFields: HEADER_ELEMENT_KEYS[] 
}) => {
  return (
    <thead className="bg-bg7">
      <tr className="even:border-t-[1px] even:border-t-grey-200">
        {allowedFields.map(element => {
            return <th
              scope="col"
              className="text-left text-body2 text-text-primary font-medium h-[38px] px-4 py-2.5"
              key={element}
            >
              {TABLE_HEADER_LABELS[element]}
            </th>
          })
        }
      </tr>
    </thead>)
}