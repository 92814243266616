import { Controller, useForm } from 'react-hook-form';
import InputSelect from 'components/InputSelect';
import { useEffect, useState } from 'react';
import MedicosA from 'assets/images/no_result_a.png';
import Medicos from 'assets/images/no_result_b.png';
import { PatientModel } from 'features/Patient/models/Patient.model';
import CardUser from 'components/cardUser';
import { useSpecialityRequestStatus, useAddSpecialist, useGetAssignedPersonnelList, useGetListSpecialist, useGetListSpeciality, useDeleteSpecialist, useChangeSpecialist, useGetListSpecialityRequest } from '../hooks/useSpecialist';
import { t } from 'i18next';
import ImageError from 'components/ImageError';
import BtnMore from 'assets/icons/more.svg';
import BtnTrash from 'assets/icons/trash_b.svg';
import BtnTrashDisable from 'assets/icons/trash_disable.svg';
import LoaderMini from 'components/LoaderMini';
import { onAlert } from 'components/Alert';
import Chip from 'components/Chip';
import Moment from "moment";
import CalendarIcon from 'assets/icons/icon_calendar.svg'
import BokmarkIcon from 'assets/icons/icon_bookmark.svg'
import ScrollToBottom from 'react-scroll-to-bottom';
import { useCreateGeneralNotification } from 'features/ClinicHistory/hooks/useNotification';
import { ROLE } from 'global/constants/roles';
import { EventType } from 'global/constants/eventType';
import { useParams } from 'react-router-dom';


interface WorkPlanProps {
  patientData?: PatientModel
}

const WorkPlan: React.FC<WorkPlanProps> = ({
  patientData
}) => {
  const { control } = useForm();
  // typeSpecialist
  const [typeSpecialists, setTypeSpecialists] = useState([]);
  const [typeSpecialistSelected, setTypeSpecialistSelected] = useState('');

  // specialists
  const [listSpecialists, setListSpecialists] = useState([]);
  const [assignedPersonnelList, setAssignedPersonnelList] = useState<[]>([]);
  const [assignedPersonnelListRequest, setAssignedPersonnelListRequest] = useState<[]>([]);

  const { mutateAsync: getspecialities } = useGetListSpeciality();
  const { mutateAsync: getListSpecialist, isLoading: loadingGetListSpecialist } = useGetListSpecialist();
  const { mutateAsync: addSpecialist } = useAddSpecialist();
  const { mutateAsync: getAssignedPersonnelList, isLoading: loadingGetAssignedPersonnelList } = useGetAssignedPersonnelList();
  const { mutateAsync: deletePersonnelList } = useDeleteSpecialist();
  const { mutateAsync: changeSpecialist } = useChangeSpecialist();
  const { mutateAsync: getspecialitiesRequest, isLoading: loadingGetspecialitiesRequest } = useGetListSpecialityRequest();
  const { mutateAsync: putSpecialityRequestStatus, isLoading: loadinputSpecialityRequestStatus } = useSpecialityRequestStatus();
  const {
    documentPatient
  }: {
    documentPatient?: string
  } = useParams()


  useEffect(() => {
    if (patientData?.service?.id) {
      getSpecialities();
      getGetAssignedPersonnelListRequest();

      getGetAssignedPersonnelList();
    }
  }, [patientData?.service?.id]);

  useEffect(() => {
    if (typeSpecialistSelected) {
      getSpecialitiesList();

    }
  }, [typeSpecialistSelected]);

  const getSpecialities = async () => {
    const response = await getspecialities();
    setTypeSpecialists(response.data)
    if (response.data) {
    } else {
      onAlert.error(response);
    }
  }
  const getGetAssignedPersonnelListRequest = async () => {
    const response = await getspecialitiesRequest(patientData?.service?.id);

    if (response.data) {
      setAssignedPersonnelListRequest(response.data);

    } else {
      onAlert.error(response);
    }
  }
  const getSpecialitiesList = async () => {
    let obj = {
      serviceId: patientData?.service?.id,
      typeSpecialistSelected: typeSpecialistSelected
    }
    const response = await getListSpecialist(obj);
    setListSpecialists(response.data)
    if (response.data) {

    } else {
      onAlert.error(response);
    }
  }

  const getGetAssignedPersonnelList = async () => {
    const response = await getAssignedPersonnelList(patientData?.service?.id);
    setAssignedPersonnelList(response.data);

    if (response.data) {
    } else {
      onAlert.error(response);
    }
  }

  const { mutateAsync: createCustomNotification } =
    useCreateGeneralNotification()

  async function handleNotifyDoctorOfPatientCall({
    professionalId,
    patientId
  }: {
    professionalId: string
    patientId: string
  }) {
    try {
      await createCustomNotification({
        json: {
          professionalId,
          patientId
        },
        fromRole: ROLE.SERVICE_ADMIN,
        toRole: ROLE.PATIENT,
        type: EventType.NOTIFICATION_TYPE_MEDICAL_PERSONAL_ASSIGNED
      })
    } catch (e) {
      console.log({
        e
      });
    }
  }

  const AddSpecialitiesList = async (data: any) => {
    let obj = {
      serviceId: patientData?.service?.id,
      body: { "specialistId": data.id }
    }
    const response = await addSpecialist(obj);
    if (response.data) {
      onAlert.success('Se ha asignado a ' + data.name + ' ' + data.lastname);
      await handleNotifyDoctorOfPatientCall({
        professionalId: data.id,
        patientId: documentPatient || ""
      })
      getSpecialitiesList();
      getGetAssignedPersonnelList();
    } else {
      onAlert.error(response);

    }
  }

  const deleteSpecialitiesList = async (data: any) => {
    let obj = {
      serviceId: patientData?.service?.id,
      specialistId: data.id,

    }
    const response = await deletePersonnelList(obj);
/*     console.log(response);
 */    if (response.data) {
      onAlert.success('Se ha eliminado a ' + data.name + ' ' + data.lastname);
      getSpecialitiesList();
      getGetAssignedPersonnelList();
    } else {
      onAlert.error(response);
    }
  }

  const changeAttendingDoctor = async (data: any) => {
    let obj = {
      serviceId: patientData?.service?.id,
      specialistId: data.id,
      body: { "isAttendingDoctor": true }

    }
    const response = await changeSpecialist(obj);
/*     console.log(response);
 */    if (response.data) {
      onAlert.success('Se ha cambiado el médico tratante a ' + data.name + ' ' + data.lastname);
      getSpecialities();
      getGetAssignedPersonnelListRequest();
      getGetAssignedPersonnelList();
    } else {
      onAlert.error(response);

    }

  }

  const changeStatusRequestSpecialist = async (data: any) => {
    let obj = {
      serviceId: patientData?.service?.id,
      specialtyRequestId: data.id,
      body: { "statusRequest": 2 }

    }
    const response = await putSpecialityRequestStatus(obj);
    if (response.data) {
      onAlert.success(`Has atendido la solicitud de interconsulta ${data.name}`);
      getSpecialities();
      getGetAssignedPersonnelListRequest();
      getGetAssignedPersonnelList();
    } else {
      onAlert.error(response);
      getGetAssignedPersonnelListRequest();

    }

  }
  return (
    <div className='w-full'>

      <div className="grid sm:grid-cols-2 gap-4">
        <div className='border-solid border-[1px] border-grey-200 rounded-[16px] p-4'>
          <p className='text-body1 row-span-1 col-span-full mb-4'>{t('select_a_specialty')}</p>

          <div className='row-span-1 col-span-full relative'>
            <div className='flex items-center gap-2'>
              <div className='grow'>
                <Controller
                  control={control}
                  name='selecte2'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label="Especialidad"
                      name="selecte2"
                      required={false}
                      hidePlaceholder
                      onSelect={val => {
/*                         console.log(val);
 */                        setTypeSpecialistSelected(val);
                        onChange(val);
                      }}
                      value={value}
                      ref={ref}
                      data-testid="select-medical-specialist"
                    >
                      {typeSpecialists.map((e: any) => <option value={e.value} key={e.id}>{e.label}</option>)}
                    </InputSelect>
                  )}
                />
              </div>


            </div>
            <div className="flex mt-[16px] mb-[16px]">
              <Chip variant='primary' className=' justify-center mr-[4px] ' label={`Desde : ${Moment(patientData?.service?.serviceDate).format('DD/MM/YYYY')} `} onClose={() => { }} />
              <Chip variant='primary' className=' justify-center ' label={`Hasta : ${Moment(patientData?.service?.plannedReleaseDate).format('DD/MM/YYYY')}`} onClose={() => { }} />

            </div>
          </div>
          <div className={`${loadingGetListSpecialist ? 'relative' : ''} grid 2xl:grid-cols-2 gap-4 mt-4 flex flex-col gap-4`}>
            {loadingGetListSpecialist &&
              <LoaderMini />
            }

            {
              listSpecialists.length === 0 ? (
                <ImageError className={'border-dashed border-[2px] rounded-[16px] min-h-[352px]	h-full  text-left text-caption '} image={MedicosA}>
                  No se encontraron especialistas disponibles.
                </ImageError>
              ) : (
                listSpecialists.map((el: any) =>
                  <CardUser
                    key={el.id + el.name}
                    showBotton={true}
                    showBtn={true}
                    id={el.id}
                    icon={BtnMore}
                    chips={el?.daysAvailable?.map((item: any) => { return ({ label: item?.day, icon: CalendarIcon, label2: item?.availability, icon2: BokmarkIcon }) })}
                    validate={true}
                    user={{ ...el, description: el.specialty + ' - ' + (el.phone ? el.phone : el.email) }}
                    onClick={(data: any) => {
                      AddSpecialitiesList(data);

                    }}
                  />
                )
              )
            }</div>
        </div>
        <div className="col">
          {assignedPersonnelListRequest.length !== 0 &&
            <div className={`${loadingGetspecialitiesRequest ? 'relative' : ''}  mb-4  border-solid border-[1px] border-grey-200 rounded-[16px] p-4 flex flex-col `}>
              {(loadingGetspecialitiesRequest || loadinputSpecialityRequestStatus) &&
                <LoaderMini />
              }
              <p className='text-body1 mb-2'>{t('requested_specialty')}</p>

              {
                assignedPersonnelListRequest.length === 0 ? (
                  <ImageError className={'border-dashed border-[2px] rounded-[16px] min-h-[352px] h-full text-left text-caption 	'} image={Medicos}>
                    Aquí se mostrara los especialistas solicitados.
                  </ImageError>
                ) : (
                  <ScrollToBottom
                    followButtonClassName='ScrollToBottom__btn__top'
                    scrollViewClassName=" overflow-y-scroll scrollbar grid  gap-2 "
                    className=" bg-white rounded-lg  w-full h-[190px]  "
                  >
                    {assignedPersonnelListRequest.map((e: any) =>
                      <CardUser
                        key={e.id + 'assignedPersonnelListRequest'}
                        id={e.id}
                        showCheckbtn={true}
                        onlyText={true}
                        user={{ name: e.especialidad, id: e.id, status: e.statusRequest }}
                        chipsOnlyText={[{ label: e.statusRequestName }]}
                        onClickCheckbtn={(data: any) => {
                          changeStatusRequestSpecialist(data);

                        }}
                      />
                    )}
                  </ScrollToBottom>)
              }

            </div>
          }
          <div className={`${loadingGetAssignedPersonnelList ? 'relative' : ''} border-solid border-[1px] border-grey-200 rounded-[16px] p-4 flex flex-col gap-4`}>

            <p className='text-body1'>{t('personal_allowance')}</p>
            {loadingGetAssignedPersonnelList &&
              <LoaderMini />
            }
            {
              assignedPersonnelList.length === 0 ? (
                <ImageError className={'border-dashed border-[2px] rounded-[16px] min-h-[352px] h-full text-left text-caption 	'} image={Medicos}>
                  Aquí se mostrara los especialistas seleccionados.
                </ImageError>
              ) : (
                assignedPersonnelList.map((e: any) =>
                  <CardUser
                    key={e.id}
                    showBotton={true}
                    showBtn={true}
                    icon={e.isAttendingDoctor !== 1 ? BtnTrash : BtnTrashDisable}
                    user={{ ...e, description: e.specialty + ' - ' + (e.phone ? e.phone : e.email) }}
                    showToggle={true}
                    validate={e.isAttendingDoctor !== 1 ? true : false}
                    validateToggle={e.isAttendingDoctor !== 1 ? true : false}
                    labelIcon={e.isAttendingDoctor !== 1 ? '' : 'Médico tratante'}
                    labelToggle={'Deseas asignarlo como médico tratante'}
                    chips={e?.daysAvailable?.map((item: any) => {

                      return ({ label: item, icon: CalendarIcon })

                    })}

                    setToggle={(data: any) => {
                      changeAttendingDoctor(data);
                    }}
                    onClick={(data: any) => {
                      deleteSpecialitiesList(data);
                    }}
                  />
                )
              )
            }

          </div>
        </div>
      </div>
    </div >
  );
};

export default WorkPlan;