import React from "react";
import { AnnotationsCardProps } from ".";

interface AnnotationsCardPropsInlineUser extends AnnotationsCardProps {
  emitter: string;
  emitterRole: string;
  viewerIsSender: boolean;
  extraTags?: {
    name: string;
    className: string;
  }[]
}

export const getStyleClasses = ({
  viewerIsSender
}: {
  viewerIsSender: boolean;
}) => {

  let orientationClass = "";
  let borderClass = "";
  if (viewerIsSender) {
    orientationClass = "flex-row-reverse"
    borderClass = "border-l border-l-[#ADADAD]"
  } else {
    orientationClass = "flex-row"
    borderClass = "border-r border-r-[#ADADAD]"
  }
  return {
    orientationClass,
    borderClass
  }
}

export const UserInlinedAnnotationsCard: React.FC<AnnotationsCardPropsInlineUser> = ({
  commentary,
  description,
  className,
  emitter,
  emitterRole,
  viewerIsSender,
  extraTags
}) => {
  const {orientationClass, borderClass} = getStyleClasses({viewerIsSender});


  return (
    <div className={`rounded-[16px] p-[16px] w-full flex ${orientationClass} ${className}`}>
      <div className={`mr-4 p-2 ${borderClass}`}>
        <div className="font-poppins font-medium text-[14px] text-[#141414]">
          {emitter}
        </div>
        <div className="font-poppins font-light text-[12px] text-[#545454]">
          {emitterRole}
        </div>
      </div>
      <div className="flex-1">
        <div className="w-full flex flex-row items-center">
          <div className='text-left text-Fs9 text-gray-500 w-fit'>
            {commentary}
          </div>
          {
            extraTags?.length ? <div className="ml-2">
              {extraTags.map((tag, i) => (
                <div key={i} className={`${tag.className}`}>{tag.name}</div>
              ))}
            </div> : null
          }
        </div>
        <div className='text-left text-Fs8 font-medium text-text-primary overflow-auto' data-testid="content">
          {description?.split("\n").map((e: any, i: any) =>
            <React.Fragment
              key={i}>
              {e}
              <br />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}