import { FC, useState } from "react"
import { NotificationCardActionsProps, NotificationCardHeaderProps, ReadNotificationCardFooterProps, ReadNotificationCardHeaderProps } from "./types"
import { notificationVariants } from "./NotificationCard.const"
import { getFooterDate, getHeaderDate, getLastname, getName } from "../utils"
import { EventType } from "global/constants/eventType"
import Button from "components/Button"
import { MeetingVideoCall } from "components/MeetingVideoCall"
import { variants } from "../NotificationsCounter/NotificationsCounterConst"
import { markNotificationAsRead, NotificationType, PatientPlanAssignedPersonnel, useMarkNotificationAsRead } from "features/Notifications/services"
import { PatientModel } from "features/Patient/models/Patient.model"
import { useGetPatientData } from "features/shared/hooks/useGetPatientFullData"
import useUser from "features/shared/hooks/useUser"
import { useHistory, useLocation } from "react-router-dom"
import WhitePressureIcon from "assets/icons/icon_pressure_white.svg"
import ReportDevice from "../ReportDeviceNotifications"
import { calculatePotentialNotificationActionRoute } from "../utils.react"
import { ROLE } from "global/constants/roles"
import { useGetAllPlanToday } from "features/PatientDashboard/hooks/userPlanToday"
import { useCreateGeneralNotification } from "features/ClinicHistory/hooks/useNotification"

export const NotificationCardActionCall = ({
  notification
}: {
  notification: NotificationCardActionsProps['notification']
}) => {
  const { mutateAsync: asyncGetPatientData } = useGetPatientData();
  const { userCurrent } = useUser();
  const [calling, setIsCalling] = useState(false);
  const [startCallData, setStartCallData] = useState<{
    userCurrent: any,
    allDataPatient: PatientModel,
    start: boolean
  } | null>();
  const { data } = useGetAllPlanToday();
  const planToday = data?.data

  const notificationType = notification?.data?.body?.type || notification?.data?.body?.eventType;
  const { markNotificationAsRead } = useMarkNotificationAsRead()

  const getPatientData = async () => {
    if (!notification?.data?.body?.patientId) {
      return;
    }
    const { data } = await asyncGetPatientData(`${notification?.data?.body?.patientId}`);
    return data as PatientModel;
  }

  const { mutateAsync: createCustomNotification } =
    useCreateGeneralNotification()

  async function handleNotifyDoctorOfPatientCall({
    patientId
  }: {
    patientId: string
  }) {
    try {
      await createCustomNotification({
        json: {
          patientId,
        },
        fromRole: ROLE.PATIENT,
        toRole: ROLE.HEALTH_PROFESSIONAL,
        type: EventType.NOTIFICATION_TYPE_PATIENT_CALL
      })
    } catch (e) {
      console.log({
        e
      });
    }
  }

  const handleClickAction = async () => {
    if (!notification) {
      return;
    }

    if (calling) {
      return;
    }

    try {
      if (userCurrent?.roleSlug === ROLE.PATIENT) {
        // Patient calling Dr.
        if ([EventType.NOTIFICATION_TYPE_DOCTOR_CALL, EventType.NOTIFICATION_TYPE_SPECIALIST_CALL].includes(notificationType || "")) {
          const assignedDoc = (planToday.assignedPersonnel || []).find((element: PatientPlanAssignedPersonnel) => {
            return element.isAttendingDoctor
          })
          if (planToday?.currentService.id && assignedDoc) {
            markNotificationAsRead(`${notification?.notificationId}`)
            await handleNotifyDoctorOfPatientCall({
              patientId: `${userCurrent?.id}`
            })
            setStartCallData({
              userCurrent,
              allDataPatient: {
                service: {
                  id: planToday?.currentService.id
                }
              } as any,
              start: true
            })
          }
        }
      } else if (userCurrent?.roleSlug === ROLE.HEALTH_PROFESSIONAL) {  //Health professional
        if ([EventType.NOTIFICATION_TYPE_PATIENT_CALL, EventType.NOTIFICATION_TYPE_DOCTOR_CALL, EventType.NOTIFICATION_TYPE_EMERGENCY_CALL].includes(notificationType || "")) {
          // Dr. calling patient.
          const patientData = await getPatientData();
          if (patientData) {
            setStartCallData({
              userCurrent,
              allDataPatient: patientData,
              start: true
            })
          }
        }
      }
    } catch (e) {
      console.error({
        e
      });
    }
    setIsCalling(false);
  }

  const closeMeeting = async () => {
    setStartCallData(null)
  }

  if (!notification) {
    return <>You must provide a notification</>
  }

  return (
    <div className='w-fit-content'>
      <div>
        <div
          className={`p-3 ${variants?.call?.active?.bg} rounded-lg min-h-[16px] cursor-pointer inline-block pl-4 pr-4`}
          onClick={() => handleClickAction()}
        >
          <span className="flex flex-row items-center gap-2 pr-4">
            <img
              src={variants?.call?.active?.iconName}
              alt={variants?.call?.active?.iconName}
            />
            <span className={'text-white text-sm text-caption'}>
              Llamar
            </span>
          </span>
        </div>
      </div>
      {
        startCallData?.start ? <div>
          <MeetingVideoCall
            planId={startCallData?.allDataPatient?.service?.id}
            userId={startCallData?.userCurrent?.id}
            userProfile={startCallData?.userCurrent?.profile}
            getIsClose={closeMeeting}
            initCall={startCallData?.start}
          />
        </div> : null
      }

    </div>
  )
}

export const NotificationCardActionReport = ({
  notification
}: {
  notification: NotificationCardActionsProps['notification']
}) => {
  const eventType = notification?.data?.body?.eventType || notification?.data?.body?.type;
  const alexaPairingError = eventType === EventType.NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_FAILED;
  const isReport = eventType === EventType.NOTIFICATION_TYPE_REPORT_DEVICE;
  const history = useHistory();
  const [openReport, setOpenReport] = useState(false);
  const location = useLocation();
  const { userCurrent } = useUser();

  const handleClickAction = async () => {
    let newRoute = calculatePotentialNotificationActionRoute({
      notification,
      location: location as any,
      currentUserRoleSlug: userCurrent?.roleSlug
    })
    if (newRoute) {
      await markNotificationAsRead(`${notification?.notificationId}`)
      history.push(
        newRoute
      )
    } else if (isReport) {
      setOpenReport(true);
    } else if (alexaPairingError) {
      console.log("Handling report modal display... TODO");
    } else {
      console.log("Action hasn't been handled...");
    }
  }

  return (
    <div className='w-fit-content'>
      <div>
        <div
          className={`p-3 ${variants?.report?.active?.bg} rounded-lg min-h-[16px] cursor-pointer inline-block pl-4 pr-4`}
          onClick={handleClickAction}
        >
          <span className="flex flex-row items-center gap-2 pr-4">
            <img
              src={WhitePressureIcon}
              alt={WhitePressureIcon}
            />
            <span className={'text-white text-sm text-caption'}>
              {alexaPairingError ? 'Generar' : 'Revisar'}
            </span>
          </span>
        </div>
      </div>
      <div>
        {
          openReport ? <ReportDevice
            onCloseModal={() => {
              setOpenReport(false);
            }}
            onSubmitForm={async () => {
              await markNotificationAsRead(`${notification?.notificationId}`)
              setOpenReport(false);
              //TODO: Handle this better.
              window.location.reload();
            }}
            deviceSelected={notification?.data.body.device}
          /> : null
        }
      </div>
    </div>
  )
}

export const NotificationCardPatientReleased = ({
  notification
}: {
  notification: NotificationCardActionsProps['notification']
}) => {
  const location = useLocation();
  const history = useHistory();
  const [handlingClick, setHandlingClick] = useState(false);
  const { userCurrent } = useUser();

  const handleClickAction = async () => {
    if (handlingClick) {
      return;
    }
    setHandlingClick(true);
    let finalRoute: string | null | undefined;
    if (userCurrent?.roleSlug === ROLE.HEALTH_PROFESSIONAL) {
      finalRoute = calculatePotentialNotificationActionRoute({
        notification,
        location: location as any
      })
    }
    if (finalRoute !== '/') {
      try {
        await markNotificationAsRead(`${notification?.notificationId}`);
      } catch (e) {
        console.error({ e });
        setHandlingClick(false);
        return;
      }
    }
    setHandlingClick(false);
    if (finalRoute) {
      history.push(
        finalRoute
      )
    }
  }

  return (
    <div className='w-fit-content'>
      <div>
        <div
          className={`p-3 ${variants?.queryWorkplan?.active?.bg} rounded-lg min-h-[16px] cursor-pointer inline-block pl-4 pr-4`}
          onClick={handleClickAction}
        >
          <span className="flex flex-row items-center gap-2">
            <img
              src={variants?.queryWorkplan?.active?.iconName}
              alt={variants?.queryWorkplan?.active?.iconName}
            />
            <span className={'text-white text-sm text-caption'}>
              Ver Detalle
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export const NotificationCardActionPrescription = ({
  notification
}: {
  notification: NotificationCardActionsProps['notification']
}) => {
  const location = useLocation();
  const history = useHistory();
  const [handlingClick, setHandlingClick] = useState(false);

  const handleClickAction = async () => {
    if (handlingClick) {
      return;
    }
    setHandlingClick(true);
    const finalRoute = calculatePotentialNotificationActionRoute({
      notification,
      location: location as any
    })
    if (finalRoute !== '/') {
      try {
        await markNotificationAsRead(`${notification?.notificationId}`);
      } catch (e) {
        console.error({ e });
        setHandlingClick(false);
        return;
      }
      setHandlingClick(false);
    }
    if (finalRoute) {
      history.push(
        finalRoute
      )
    }
  }

  return (
    <div className='w-fit-content'>
      <div>
        <div
          className={`p-3 ${variants?.queryWorkplan?.active?.bg} rounded-lg min-h-[16px] cursor-pointer inline-block pl-4 pr-4`}
          onClick={handleClickAction}
        >
          <span className="flex flex-row items-center gap-2">
            <img
              src={variants?.queryWorkplan?.active?.iconName}
              alt={variants?.queryWorkplan?.active?.iconName}
            />
            <span className={'text-white text-sm text-caption'}>
              Ver Detalle
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export const NotificationCardActionRecommendation = ({
  notification
}: {
  notification: NotificationCardActionsProps['notification']
}) => {
  const history = useHistory();
  const location = useLocation();
  const handleClickAction = async () => {

    if (!notification?.data?.body?.patientId) {
      console.error("No patient ID provided...", notification);
    }
    const finalRoute = calculatePotentialNotificationActionRoute({
      notification,
      location: location as any
    })
    if (finalRoute && finalRoute !== '/') {
      try {
        await markNotificationAsRead(`${notification?.notificationId}`);
        history.push(finalRoute);
      } catch (e) {
        console.error({
          e
        });
      }
    } else {
      console.log("Intent not recognized...", {
        finalRoute
      });
    }
  }

  return (
    <div className='w-fit-content'>
      <div>
        <div
          className={`p-3 ${variants?.queryWorkplan?.active?.bg} rounded-lg min-h-[16px] cursor-pointer inline-block pl-4 pr-4`}
        >
          <span className="flex flex-row items-center gap-2" onClick={handleClickAction}>
            <img
              src={variants?.queryWorkplan?.active?.iconName}
              alt={variants?.queryWorkplan?.active?.iconName}
            />
            <span className={'text-white text-sm text-caption'}>
              Ver Detalle
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export const NotificationCardActionAnnotations = ({
  notification
}: {
  notification: NotificationCardActionsProps['notification']
}) => {
  const history = useHistory();
  const location = useLocation();
  const handleClickAction = async () => {
    if (!notification?.data?.body?.patientId) {
      console.error("No patient ID provided...", notification);
      return;
    }
    const finalRoute = calculatePotentialNotificationActionRoute({
      notification,
      location: location as any
    })
    if (finalRoute && finalRoute !== '/') {
      try {
        await markNotificationAsRead(`${notification?.notificationId}`);
        history.push(finalRoute);
      } catch (e) {
        console.error({
          e
        });
      }
    } else {
      console.log("Intent not recognized...", {
        finalRoute
      });
    }
  }

  return (
    <div className='w-fit-content'>
      <div>
        <div
          className={`p-3 ${variants?.annotations?.active?.bg} rounded-lg min-h-[16px] cursor-pointer inline-block pl-4 pr-4`}
        >
          <span className="flex flex-row items-center gap-2" onClick={handleClickAction}>
            <img
              src={variants?.annotations?.active?.iconName}
              alt={variants?.annotations?.active?.iconName}
            />
            <span className={'text-white text-sm text-caption'}>
              Ver Detalle
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export const NotificationCardActions: FC<NotificationCardActionsProps> = ({
  variant,
  onClickAction,
  notification
}) => {
  const notificationType = notification?.data?.body?.eventType || notification?.data?.body?.type;
  if (variant === NotificationType.call) {
    return <NotificationCardActionCall notification={notification} />
  }
  if (variant === NotificationType.report) {
    return <NotificationCardActionReport notification={notification} />
  }
  if (variant === NotificationType.prescription) {
    //Only applyable for the patient.
    return <NotificationCardActionPrescription notification={notification} />
  }
  if (variant === NotificationType.recommendation) {
    //Only applyable for the patient.
    return <NotificationCardActionRecommendation notification={notification} />
  }
  if (variant === NotificationType.annotations || [
    EventType.NOTIFICATION_TYPE_NOTE_DR_DAILY_EVOL,
    EventType.NOTIFICATION_TYPE_NOTE_PATIENT,
  ].includes(notificationType || "")) {
    return <NotificationCardActionAnnotations notification={notification} />
  }

  if (notificationType === EventType.NOTIFICATION_TYPE_PATIENT_RELEASED) {
    return <NotificationCardPatientReleased notification={notification} />
  }

  const selectedVariant = notificationVariants[variant]
  return (
    <div className='w-fit-content'>
      <div>
        <div
          className={`p-3 ${selectedVariant?.bg} rounded-lg min-h-[16px] cursor-pointer inline-block pl-4 pr-4`}
          onClick={onClickAction}
        >
          <span className="flex flex-row items-center gap-2 pr-4">
            <img
              src={selectedVariant?.iconName}
              alt={selectedVariant?.iconName}
            />
            <span className={'text-white text-sm text-caption'}>
              {selectedVariant?.labels.action}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}


export const ReadNotificationCardHeader: FC<
  ReadNotificationCardHeaderProps
> = ({ notification, onClickAction, alert }) => {
  const name = getName(notification.name)
  const lastname = getLastname(notification.name)
  const selectedVariant = notificationVariants[notification.notificationType]
  const date = notification.createdAt
    ? getHeaderDate(notification.createdAt)
    : null

  return (
    <div className={'flex flex-row justify-between items-center w-[100%]'}>
      {alert ? (
        <>
          <span className={`flex ${selectedVariant?.color}`}>
            <img
              className="mr-[17px]"
              src={selectedVariant?.iconAlert}
              alt=""
              width={20}
              height={20}
            />
            {selectedVariant?.titleType}
          </span>
          <span className={'text-caption text-[#ADADAD]'}>{date}</span>
        </>
      ) : (
        <div className={'flex flex-row items-center gap-2'}>
          <div
            className={
              'rounded-full bg-[#DFF5FF] text-[#008EA3] w-8 h-8 flex justify-center items-center font-semibold'
            }
          >
            {name.charAt(0)}
            {lastname.charAt(0)}
          </div>
          <span className={'text-body2'}>
            {name} {lastname}
          </span>
        </div>
      )}
      {notification.notificationType === 'report' ? (
        <div>
          <Button
            className={`p-2 w-[135px] h-[32px] rounded-lg ${selectedVariant?.bg}`}
            onClick={onClickAction}
          >
            <span className="flex flex-row items-center gap-2 pr-4">
              <img
                src={selectedVariant?.iconName}
                alt={selectedVariant?.iconName}
                className="sm:w-[12px] sm:h-[12px]"
              />
              <span className={'text-white text-caption'}>
                {selectedVariant?.labels.action}
              </span>
            </span>
          </Button>
        </div>
      ) : null}
    </div>
  )
}

export const ReadNotificationCardFooter: FC<
  ReadNotificationCardFooterProps
> = ({ notification }) => {
  const getFooterContent = () => {
    const selectedVariant = notificationVariants[notification.notificationType]
    const date = getFooterDate(notification.readAt)
    return `${selectedVariant?.labels.readByPreffix} ${notification.attendedName} el ${date}`
  }
  return (
    <div className={'flex flex-row justify-between items-center w-[100%]'}>
      <span className={'text-caption text-[#ADADAD]'}>
        {getFooterContent()}
      </span>
    </div>
  )
}

export const NotificationCardHeader: FC<NotificationCardHeaderProps> = ({
  notification,
  alert,
}) => {
  const name = getName(notification.name)
  const lastname = getLastname(notification.name)
  const date = notification.createdAt
    ? getHeaderDate(notification.createdAt)
    : null
  const readAtDate = notification.readAt ? getHeaderDate(notification.readAt) : null
  const selectedVariant = notificationVariants[notification.notificationType];
  const isAlexa = notification.deviceSource === 'alexa';
  const isUrgent = [notification.data?.body?.eventType].includes(EventType.NOTIFICATION_TYPE_EMERGENCY_CALL);
  return (
    <div className={'flex flex-col justify-between items-left w-[100%]'}>
      {alert ? (
        <span className={`flex ${selectedVariant?.color}`}>
          <img
            className="mr-[17px]"
            src={selectedVariant?.iconAlert}
            alt=""
            width={20}
            height={20}
          />
          {selectedVariant?.titleType}
        </span>
      ) : (
        <div className={'flex flex-row items-center gap-2 pb-1'}>
          <span className={'text-body2'}>
            {name} {lastname}
          </span>
          <div className='flex-1 flex justify-end'>
            {
              isAlexa && <span className='text-xs p-[2px_12px] gap-[10px] rounded-lg opacity-1 bg-[#DFF5FF] text-[#008EA3]'>
                Alexa
              </span>
            }
            {
              isUrgent && <span className='text-xs p-[2px_12px] gap-[10px] rounded-lg opacity-1 bg-[#FFE8E8] text-[#E21010] ml-1'>
                URGENTE
              </span>
            }
          </div>
        </div>
      )}
      <span className={'text-caption text-[#585A5B]'}>{date || readAtDate || ""}</span>
    </div>
  )
}

export const NotificationCardContainer: FC = ({ children }) => {
  return (
    <div
      className={
        'box-border flex flex-col items-start p-4 gap-4 w-[337px] bg-[#FFFFFF] border border-[#E2E2E2] shadow-[0px_4px_16px_rgba(0,0,0,0.05)] rounded-2xl flex-none order-0 flex-grow-0 shadow-[0px_4px_16px_0px_#0000000D]'
      }
    >
      {children}
    </div>
  )
}

export const NotificationCardDescription: FC = ({ children }) => {
  return <div className="text-body2 text-grey-600 flex-1">{children}</div>
}