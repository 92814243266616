export enum AVAILABLE_ACTIONS {
    ATTENTIONS_HISTORY = "ATTENTIONS_HISTORY",
    EDIT_PATIENT = "EDIT_PATIENT",
    EDIT_ATTENTION = "EDIT_ATTENTION",
    NEW_ATTENTION = "NEW_ATTENTION",
    ATTENTION_DETAILS = "ATTENTION_DETAILS",
    VISITS_HISTORICAL = "VISITS_HISTORICAL",
    ACTIVE_PATIENTS = "ACTIVE_PATIENTS",
    PATIENT_DETAILS_WORKPLAN = "PATIENT_DETAILS_WORKPLAN",
    WORKPLAN = "WORKPLAN",
    WORKPLAN_TREAMENT = "WORKPLAN_TREAMENT",
    WORKPLAN_MEDICAL_PERSONEL = "WORKPLAN_MEDICAL_PERSONEL",
    WORKPLAN_DEVICES = "WORKPLAN_DEVICES",
    WORKPLAN_RECOMMENDATIONS = "WORKPLAN_RECOMMENDATIONS",
    WORKPLAN_ANNOTATIONS = "WORKPLAN_ANNOTATIONS",
  }