import React from "react"

export const PersonSnippet = (element: {
    name: string,
    lastname: string,
    role: string
}) => {
    return (
        <React.Fragment>
            <div className="flex justify-center items-center">
                <div className="bg-[#DFF5FF] rounded-full flex items-center justify-center font-poppins font-normal font-medium text-lg text-center text-[#008EA3] p-2 h-fit w-[2.5em] h-[2.5em]" style={{
                    fontWeight: "500"
                }}>
                    {element.name.slice(0, 1)}{element.lastname.slice(0, 1)}
                </div>
            </div>
            <div className="flex flex-col justify-center pl-2">
                <div className="font-poppins font-medium text-[14px] flex items-center text-[#141414]"><span>{element.name}</span> <span>{element.lastname}</span></div>
                <div className="font-poppins font-normal text-[12px] text-[#545454]">{element.role}</div>
            </div>
        </React.Fragment>
    )

}