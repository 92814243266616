import { FC, ReactNode, useState } from 'react'

import IconClose from 'assets/icons/icon_close.svg'
import Button from 'components/Button'
import { PersonSnippet } from '../PersonSnippet'

interface MessageModalProps {
  onClose: () => void
  title: ReactNode
  withDivider?: boolean
  maxWidth?: string
  showModal: boolean
  onSubmit: (newMessage: string) => void
  sendToElement: {
    name: string
    lastname: string
    specialty: string
  }
  disabled?: boolean
  maxLength?: number;
}

export const CounterTextArea = ({
  onChange,
  value,
  maxLength,
  disabled
}: {
  onChange: (text: string) => void,
  value?: string,
  maxLength: number
  disabled?: boolean
}) => {
  const [localValue, setLocalValue] = useState(value || "");
  return (
    <div>
      <textarea
        className="w-full border border-[#F1F1F1] rounded-[8px] p-[8px] bg-[#F6F6F6] font-poppins font-normal text-[14px] text-[#585A5B]"
        placeholder="Escribe un comentario"
        onChange={(value) => {
          setLocalValue(value.target.value || "");
          onChange(value.target.value || "");
        }}
        value={localValue}
        maxLength={maxLength}
        rows={5}
        disabled={disabled}
      />
      <div className="flex justify-end mr-2">
        <span className="font-poppins font-normal text-[12px] flex items-center text-[#ADADAD]">{(localValue || "").length}/{maxLength}</span>
      </div>
    </div>
  )
}

export const MessageModal: FC<MessageModalProps> = ({
  onClose,
  showModal,
  title,
  onSubmit,
  sendToElement,
  disabled,
  maxLength
}) => {
  const [messageValue, setMessageValue] = useState('');

  return (
    <>
      {showModal &&
        <div className="">
          <div className="
          box-border flex flex-col items-start p-[24px_16px] gap-[24px] border border-[#F1F1F1] shadow-lg rounded-[16px] absolute
          justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative py-8 px-6 w-full max-w-[608px] 2xl:max-w-[70%]">
              <div data-testid="modal_add_device" className="border-0 rounded-lg shadow-lg relative w-full  bg-white outline-none focus:outline-none overflow-hidden p-[16px]">
                <div className=" pb-[24px] flex justify-between items-center">
                  <span className="text-left text-Fs8 font-medium">{title}</span>
                  <button
                    disabled={disabled}
                    onClick={onClose}
                    className="h-[30px] w-[30px] flex items-center justify-center"
                  >
                    <img src={IconClose} alt="close" className="min-w-[1.5rem] min-h-[1.5rem] w-6 h-6" />
                  </button>
                </div>
                <div className='flex flex-col'>
                  <div className="flex flex-1 mb-2">
                    <PersonSnippet
                      name={sendToElement.name}
                      lastname={sendToElement.lastname}
                      role={sendToElement.specialty}
                    />
                  </div>
                  <div>
                    <form onSubmit={() => onSubmit(messageValue)}>
                      <CounterTextArea
                        onChange={(newValue) => {
                          setMessageValue(newValue);
                        }}
                        maxLength={maxLength || 200}
                      />
                    </form>
                  </div>
                </div>
                <div className="flex gap-4 pt-4 pb-4 justify-end">
                  <Button disabled={disabled} data-testid="btn_medication"
                    id="btn_add_medications"
                    className='border-primary-main bg-[#008EA3] pr-6 pl-6 w-fit' type="submit"
                    variant='primary' size="large"
                    onClick={() => {
                      onSubmit(messageValue)
                    }}>Enviar Mensaje</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      }
    </>)
}

