import { useEffect, useMemo, useState } from 'react'
import { format as formatDate, isValid } from 'date-fns'

import { NotificationType } from '../services'
import { ICardDateProps } from 'features/ClinicHistory/ListPatients/Table/TableUpperActions.utils'
import { getDateFromQueryParam } from '../components/utils'

export interface IFilterData {
  dateRange?: ICardDateProps,
  notificationType?: NotificationType
  patientTextSearch?: string
  deviceSource?: string
}


export const useNotificationsFiltersDashboard = () => {
  const [filterData, setFilterData] = useState<IFilterData>();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const dateRange = {
      startDateRange: getDateFromQueryParam("from", query) || undefined,
      endDateRange: getDateFromQueryParam("to", query) || undefined
    }
    const notificationType = query.get('type') as NotificationType;
    const patientTextSearch = query.get('patientTextSearch') as string;
    const deviceSource = query.get('deviceSource') as string;
    setFilterData({
      dateRange,
      notificationType: notificationType || null,
      patientTextSearch: patientTextSearch || undefined,
      deviceSource: deviceSource || undefined
    })
  }, []);

  const queryData = useMemo(() => {
    const queryElements = new URLSearchParams();
    if(filterData?.dateRange?.startDateRange){
      queryElements.set('from', formatDate(filterData.dateRange.startDateRange, 'yyyy-MM-dd'));;
    }
    if(filterData?.dateRange?.endDateRange){
      queryElements.set('to', formatDate(filterData.dateRange.endDateRange, 'yyyy-MM-dd'));
    }
    if(filterData?.notificationType){
      queryElements.set('type', filterData.notificationType);
    }
    if(filterData?.patientTextSearch){
      queryElements.set('patientTextSearch', filterData?.patientTextSearch);
    }
    if(filterData?.deviceSource){
      queryElements.set('deviceSource', filterData?.deviceSource);
    }
    return queryElements;
  }, [JSON.stringify(filterData || null)])

  useEffect(() => {
    if(Array.from(queryData.values()).length > 0){
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${queryData.toString()}`;
      window.history.pushState({path:newurl},'',newurl);
    }
  }, [queryData])

  return {
    setFilterData,
    filterData
  }
}

