import LabelForm from "components/LabelForm"
import IconUser from 'assets/icons/icon_user.svg';
import { PatientPlanAssignedPersonnel } from "features/Notifications/services";
import { PersonnelCard } from "./AssignedPersonnel.card";

export const AssignedPersonnel = ({
    assignedPersonnel
}: {
    assignedPersonnel: PatientPlanAssignedPersonnel[]
}) => {

    return <div className="bg-white shadow-md border-solid border-[1px] border-grey-200 rounded-[16px] p-[16px] row-span-1 col-span-full">
        <div className="flex justify-between items-center mb-4">
            <LabelForm
                icon={IconUser}
                label="Personal asignado"
                className="text-[14px] leading-[20px] font-medium"
            />
        </div>
        <div>
            {
                !assignedPersonnel?.length ? (
                    <div>El paciente no tiene personal asignado</div>
                ) : null
            }
            {
                assignedPersonnel.map(element => <PersonnelCard key={element.id} record={element} />)
            }
        </div>
    </div>
}