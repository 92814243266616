import { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { routes } from 'routes/routing'
import useUser from 'features/shared/hooks/useUser'

import Loader from 'components/Loader'
import InputSelect from 'components/InputSelect'
import Breadcrumbs from 'components/Breadcrumbs'

import { ReadNotificationGroup } from './components/NotificationCard/NotificationCard'
import { NotificationsDatePicker } from './components/DatePicker'
import { useNotificationsFilters } from './hooks'
import IconEmpty from 'assets/icons/icon_empty.svg'

import {
  AttendedNotifications,
  GetAttendedNotificationsResponse,
  useGetAttendedNotifications,
} from './services'
import React from 'react'
import { ROLE } from 'global/constants/roles'
import ImageError from 'components/ImageError'

interface NotificationsDashboardBodyProps {
  notificationsPages: GetAttendedNotificationsResponse[]
}

interface NotificationsDashboardBodyGroupsProps {
  notificationsGroups: AttendedNotifications[]
  pageIndex: number
}

const NotificationsDashboardBodyGroups: FC<
  NotificationsDashboardBodyGroupsProps
> = ({ notificationsGroups, pageIndex }) => {
  return (
    <>
      {notificationsGroups?.length === 0 && pageIndex === 0 ? (
        <div className={'flex flex-row justify-center mt-6 mb-6'}>

          <ImageError className={'border-dashed border-[2px] rounded-[16px] min-h-[352px]	h-full  text-left text-caption '} image={IconEmpty}>
            <br />
            <br />
            <br />
            No tienes notificaciones atendidas, prueba cambiando los filtros.

          </ImageError>
        </div>
      ) : (
        notificationsGroups?.map((notificationsGroup, index) => (
          <ReadNotificationGroup
            key={index}
            notificationsGroup={notificationsGroup}
            defaultOpen={pageIndex === 0 && index === 0}
          />
        ))
      )}
    </>
  )
}

const NotificationsDashboardBody: FC<NotificationsDashboardBodyProps> = ({
  notificationsPages,
}) => {
  return (
    <div className={'flex flex-col gap-6'}>
      {notificationsPages.map((notifications, index) => (
        <NotificationsDashboardBodyGroups
          key={index}
          notificationsGroups={notifications?.data?.data}
          pageIndex={index}
        />
      ))}
    </div>
  )
}

const NotificationsDashboard = () => {
  const history = useHistory()
  const { userCurrent } = useUser()
  const {
    query,
    getDateFromString,
    from,
    to,
    errors,
    errorMessages,
    handleChangeDate,
    handleSelectType,
  } = useNotificationsFilters()

  const {
    getAttendedNotificationsResponse,
    getAttendedNotificationsIsLoading,
    getAttendedNotificationsIsFetching,
    hasMoreNotifications,
    fetchNextNotificationsPage,
  } = useGetAttendedNotifications({ role: userCurrent?.roleSlug, ...query })

  if (
    getAttendedNotificationsIsFetching ||
    getAttendedNotificationsIsLoading ||
    !userCurrent
  ) {
    return <Loader />
  }

  return (
    <div>
      <Breadcrumbs
        name={'Notificaciones atendidas'}
        currentPathName={'Notificaciones atendidas'}
        prevPathName={'Mis Notificaciones'}
        onClickPrev={() => history.push(routes.notifications.url)}
      />

      <div className={'flex flex-col gap-6 mt-6'}>
        <div className={'flex flex-col bg-white rounded-2xl p-4 gap-6'}>
          <div className={'flex flex-col gap-8'}>
            <div className={'flex flex-row flex-wrap justify-end gap-2'}>
              <InputSelect
                label={'Seleccionar categoría'}
                name="category"
                className={'min-w-[250px]'}
                onSelect={handleSelectType}
                value={query.type || ''}
              >
                {ROLE.PATIENT === userCurrent?.roleSlug &&
                  <React.Fragment>
                    {<option value={'call'}> {'Llamadas'} </option>}
                    {<option value={'prescription'}> {'Prescripciones'} </option>}
                    {<option value={'recommendation'}> {'Recomendaciones'} </option>}
                  </React.Fragment>

                }
                {ROLE.HEALTH_PROFESSIONAL === userCurrent?.roleSlug &&
                  <React.Fragment>
                    {<option value={'call'}> {'Llamadas'} </option>}
                    {<option value={'medication'}> {'Medicamentos'} </option>}
                    {<option value={'report'}> {'Dispositivos'} </option>}
                  </React.Fragment>

                }
                {ROLE.SERVICE_ADMIN === userCurrent?.roleSlug &&
                  <React.Fragment>
                    {<option value={'call'}> {'Llamadas'} </option>}
                    {<option value={'report'}> {'Dispositivos'} </option>}
                    {<option value={'consultation'}> {'Interconsulta'} </option>}
                  </React.Fragment>
                }
              </InputSelect>
              <NotificationsDatePicker
                label={'Desde'}
                locale={'es'}
                selected={getDateFromString(from)}
                onChange={handleChangeDate('from')}
                hasError={!errors.includes('from')}
                errorText={errors.includes('from') ? errorMessages['from'] : ''}
              />
              <NotificationsDatePicker
                label={'Hasta'}
                locale={'es'}
                selected={getDateFromString(to)}
                onChange={handleChangeDate('to')}
                hasError={!errors.includes('to')}
                errorText={errors.includes('to') ? errorMessages['to'] : ''}
              />
            </div>
          </div>

          {getAttendedNotificationsResponse ? (
            <NotificationsDashboardBody
              notificationsPages={getAttendedNotificationsResponse.pages}
            />
          ) : null}

          {hasMoreNotifications ? (
            <div className={'flex flex-row justify-center'}>
              <button
                className={'text-[#00B1CC] font-semibold'}
                onClick={() => fetchNextNotificationsPage()}
              >
                Ver más
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default NotificationsDashboard

