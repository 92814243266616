import IconCall from 'assets/icons/icon_call.svg'
import IconCallGreen from 'assets/icons/icon_call_green.svg'
import IconFileGreen from 'assets/icons/icon_file_blue.svg'
import IconFileWhite from 'assets/icons/icon_file_white.svg'
import IconWatch407FE7 from 'assets/icons/icon_watch_407FE7.svg';
import IconWatch from 'assets/icons/icon_watch.svg'
import IconHealth from 'assets/icons/icon_health_white.svg'
import IconMessage from 'assets/icons/icon_message.svg'
import IconMessage407FE7 from 'assets/icons/icon_message_407FE7.svg'
import IconBulletCheckWhite from 'assets/icons/icon_bullet_check_white.svg'
import IconBulletCheck6FA2B6 from 'assets/icons/icon_bullet_check_6FA2B6.svg'
import { NotificationType } from 'features/Notifications/services';

export const variants = {
  call: {
    active: {
      iconName: IconCall,
      label: 'Llamadas',
      bg: 'bg-[#48B16E]',
      labelClass: 'text-sm text-white',
      counter: {
        textCircleStyle: 'text-sm text-[#48B16E] bg-white',
      },
    },
    inactive: {
      iconName: IconCallGreen,
      label: 'Llamadas',
      bg: 'bg-white border border-solid border-[#48B16E]',
      labelClass: 'text-sm text-[#48B16E]',
      counter: {
        textCircleStyle: 'text-sm text-[#48B16E] bg-[#48B16E1A]',
      },
    }
  },
  medication: {
    active: {
      iconName: IconHealth,
      label: 'Medicamentos',
      bg: 'bg-[#00B1CC]',
      labelClass: 'text-sm text-[#00B1CC]',
      counter: {
        textCircleStyle: 'text-[#528EB7]',
      },
    },
    inactive: {
      iconName: IconHealth,
      label: 'Medicamentos',
      bg: 'bg-[#00B1CC]',
      labelClass: 'text-sm text-white',
      counter: {
        textCircleStyle: 'text-[#528EB7]',
      },
    }
  },
  report: {
    active: {
      iconName: IconWatch,
      label: 'Dispositivos',
      bg: 'bg-[#407FE7]',
      labelClass: 'text-sm text-white',
      counter: {
        textCircleStyle: 'text-sm text-[#407FE7] bg-white',
      },
    },
    inactive: {
      iconName: IconWatch407FE7,
      label: 'Dispositivos',
      bg: 'bg-white border border-solid border-[#407FE7]',
      labelClass: 'text-sm text-[#528EB7]',
      counter: {
        textCircleStyle: 'text-sm bg-[#407FE71A] text-[#407FE7]',
      },
    }
  },
  prescription: {
    active: {
      iconName: IconHealth,
      label: 'Prescripciones',
      bg: 'bg-[#00B1CC]',
      counter: {
        textCircleStyle: 'text-[#528EB7]',
      },
    },
    inactive: {
      iconName: IconHealth,
      label: 'Prescripciones',
      bg: 'bg-[#00B1CC]',
      counter: {
        textCircleStyle: 'text-[#528EB7]',
      },
    }
  },
  recommendation: {
    active: {
      iconName: IconMessage,
      label: 'Anotaciones',
      bg: 'bg-[#407FE7]',
      labelClass: 'text-sm text-white',
      counter: {
        textCircleStyle: 'text-sm text-[#407FE7] bg-white',
      },
    },
    inactive: {
      iconName: IconMessage407FE7,
      label: 'Anotaciones',
      bg: 'bg-white border border-solid border-[#407FE7]',
      labelClass: 'text-sm text-[#528EB7]',
      counter: {
        textCircleStyle: 'text-sm text-[#407FE7] bg-[#407FE71A]',
      },
    }
  },
  annotations: {
    active: {
      iconName: IconMessage,
      label: 'Anotaciones',
      bg: 'bg-[#407FE7]',
      labelClass: 'text-sm text-white',
      counter: {
        textCircleStyle: 'text-sm text-[#407FE7] bg-white',
      },
    },
    inactive: {
      iconName: IconMessage407FE7,
      label: 'Anotaciones',
      bg: 'bg-white border border-solid border-[#407FE7]',
      labelClass: 'text-sm text-[#528EB7]',
      counter: {
        textCircleStyle: 'text-sm text-[#407FE7] bg-[#407FE71A]',
      },
    }
  },
  consultation: {
    active: {
      iconName: IconFileWhite,
      label: 'Plan de Trabajo',
      bg: 'bg-[#00B1CC]',
      labelClass: 'text-sm text-white',
      counter: {
        textCircleStyle: 'text-sm text-[#00B1CC] bg-white',
      },
    },
    inactive: {
      iconName: IconFileGreen,
      label: 'Plan de Trabajo',
      bg: 'bg-white border border-solid border-[#00B1CC]',
      labelClass: 'text-sm text-[#00B1CC]',
      counter: {
        textCircleStyle: 'text-sm text-[#00B1CC] bg-[#00B1CC1A]',
      },
    }
  },
  queryWorkplan: {
    active: {
      iconName: IconFileWhite,
      label: 'Plan de Trabajo',
      bg: 'bg-[#00B1CC]',
      labelClass: 'text-sm text-white',
      counter: {
        textCircleStyle: 'text-sm text-[#00B1CC] bg-white',
      },
    },
    inactive: {
      iconName: IconFileGreen,
      label: 'Plan de Trabajo',
      bg: 'bg-white border border-solid border-[#00B1CC]',
      labelClass: 'text-sm text-[#00B1CC]',
      counter: {
        textCircleStyle: 'text-sm text-[#00B1CC] bg-[#00B1CC1A]',
      },
    }
  },
  attended: {
    active: {
      iconName: IconBulletCheckWhite,
      label: 'Atendidas',
      bg: 'bg-[#6FA2B6]',
      labelClass: 'text-sm text-white',
      counter: {
        textCircleStyle: 'text-[#6FA2B6]',
      },
    },
    inactive: {
      iconName: IconBulletCheck6FA2B6,
      label: 'Atendidas',
      bg: 'bg-white border border-solid border-[#6FA2B6]',
      labelClass: 'text-sm text-[#6FA2B6]',
      counter: {
        textCircleStyle: 'text-sm text-[#6FA2B6] bg-[#6FA2B61A]',
      },
    }
  },
  [NotificationType.patientEvents]: {
    active: {
      iconName: IconFileWhite,
      label: 'Plan de Trabajo',
      bg: 'bg-[#00B1CC]',
      labelClass: 'text-sm text-white',
      counter: {
        textCircleStyle: 'text-sm text-[#00B1CC] bg-white',
      },
    },
    inactive: {
      iconName: IconFileGreen,
      label: 'Plan de Trabajo',
      bg: 'bg-white border border-solid border-[#00B1CC]',
      labelClass: 'text-sm text-[#00B1CC]',
      counter: {
        textCircleStyle: 'text-sm text-[#00B1CC] bg-[#00B1CC1A]',
      },
    }
  }
}