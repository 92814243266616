import {
  format as formatDate,
  differenceInMinutes,
  formatDistanceToNow,
  isToday,
} from 'date-fns'
import { Notification, NotificationSummary, NotificationType } from "../services";
import { EventType } from "../../../global/constants/eventType";
import { AVAILABLE_ACTIONS } from "../../../global/constants/enums";
import { es } from 'date-fns/locale'
//import { calculateActionRoutes } from "features/shared/utils/calculateActionRoutes"
import { calculateActionRoutes } from "../../shared/utils/calculateActionRoutes";
import { ROLE } from 'global/constants/roles';

export const getName = (name: string) => {
  return name.split(' ')[0]
}

export const getLastname = (name: string) => {
  const patientName = name.split(' ')
  if (patientName.length === 2) {
    return patientName[1]
  }
  if (patientName.length > 2) {
    return patientName[2]
  }
  return ''
}

export const getHeaderDate = (createdAt: string): string => {
  const notificationDate = new Date(createdAt)
  const diffInMin = differenceInMinutes(notificationDate, new Date())
  if (Math.abs(diffInMin) < 45) {
    const formattedDistance = formatDistanceToNow(notificationDate, {
      locale: es,
    })
    return 'Hace ' + formattedDistance.replace('minutos', 'min')
  }
  return formatDate(notificationDate, 'dd/MM/yyyy HH:mm a')
}

export const getFooterDate = (createdAt: string): string => {
  return formatDate(new Date(createdAt), 'dd/MM/yyyy HH:mm a')
}

export const getAccordionTitleDate = (date: string): string => {
  return formatDate(new Date(date + 'T00:00:00'), "EEEE dd 'de' MMMM", {
    locale: es,
  })
}

export const checkIsToday = (date: string): boolean => {
  return (isToday(new Date(date + 'T00:00:00')))
}

export const getAccordionTitle = (date: string) => {
  if (checkIsToday(date)) {
    return 'Hoy'
  }
  return getAccordionTitleDate(date)
}

export const calculatePotentialNotificationActionRoute = ({
  notification,
  location,
  currentUserRoleSlug
}: {
  notification?: Notification,
  location: Location,
  currentUserRoleSlug?: string
}) => {
  const eventType = notification?.data?.body?.eventType || notification?.data?.body?.type || "";
  if (!notification) {
    return null;
  }
  const calculateReportActions = () => {
    if (eventType === EventType.NOTIFICATION_TYPE_DEVICE_ASSIGNED) {
      if (currentUserRoleSlug === ROLE.PATIENT) {
        const finalRoute = calculateActionRoutes({
          action: AVAILABLE_ACTIONS.WORKPLAN_DEVICES,
          patientId: notification?.data?.body?.patientId?.toString() || "",
          currentLocation: location
        })
        return finalRoute;
      }
      if (currentUserRoleSlug === ROLE.HEALTH_PROFESSIONAL) {
        const finalRoute = calculateActionRoutes({
          action: AVAILABLE_ACTIONS.PATIENT_DETAILS_WORKPLAN,
          patientId: notification?.data?.body?.patientId?.toString() || "",
          currentLocation: location
        })
        return finalRoute;
      }
    } else {
      const alexaPairingSuccess = eventType === EventType.NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_SUCCESSFUL;
      const alexaCodeGenerated = eventType === EventType.NOTIFICATION_TYPE_DEVICE_ALEXA_PAIRING_CODE_GENERATED;
      if (alexaPairingSuccess || alexaCodeGenerated) {
        if (!notification?.data?.body?.patientId) {
          console.error("No patient ID provided...", notification);
          return;
        }
        let chosenAction: AVAILABLE_ACTIONS = AVAILABLE_ACTIONS.ACTIVE_PATIENTS;
        if (alexaPairingSuccess) {
          chosenAction = AVAILABLE_ACTIONS.ACTIVE_PATIENTS;
        }
        if (alexaCodeGenerated) {
          chosenAction = AVAILABLE_ACTIONS.ACTIVE_PATIENTS;
        }
        const finalRoute = calculateActionRoutes({
          action: chosenAction,
          patientId: notification?.data?.body?.patientId?.toString(),
          currentLocation: location
        })
        return finalRoute;
      }
    }
  }

  const calculatePrescriptionAction = () => {
    let chosenAction: AVAILABLE_ACTIONS = AVAILABLE_ACTIONS.WORKPLAN;
    if (EventType.NOTIFICATION_TYPE_PRESCRIPTION_DELETED) {
      chosenAction = AVAILABLE_ACTIONS.WORKPLAN_TREAMENT;
    }
    if (EventType.NOTIFICATION_TYPE_PRESCRIPTION_UPDATED) {
      chosenAction = AVAILABLE_ACTIONS.WORKPLAN_TREAMENT;
    }
    if (EventType.NOTIFICATION_TYPE_PRESCRIPTION_CREATED) {
      chosenAction = AVAILABLE_ACTIONS.WORKPLAN_TREAMENT;
    }
    if (notification?.data?.body?.eventType === EventType.NOTIFICATION_TYPE_MEDICAL_PERSONAL_ASSIGNED) {
      chosenAction = AVAILABLE_ACTIONS.WORKPLAN_MEDICAL_PERSONEL;
    }
    if (notification?.data?.body?.eventType === EventType.NOTIFICATION_TYPE_DEVICE_ASSIGNED) {
      chosenAction = AVAILABLE_ACTIONS.WORKPLAN_DEVICES;
    }
    if (notification?.data?.body?.eventType === EventType.NOTIFICATION_TYPE_RECOMMENDATION) {
      chosenAction = AVAILABLE_ACTIONS.WORKPLAN_RECOMMENDATIONS;
    }
    const finalRoute = calculateActionRoutes({
      action: chosenAction,
      patientId: notification?.data?.body?.patientId?.toString() || "",
      currentLocation: location
    })
    return finalRoute;
  }

  const calculateNotificationCardActionRecommendation = () => {
    if (!notification?.data?.body?.patientId) {
      console.error("No patient ID provided...", notification);
    }
    const finalRoute = calculateActionRoutes({
      action: AVAILABLE_ACTIONS.WORKPLAN_RECOMMENDATIONS,
      patientId: notification?.data?.body?.patientId?.toString() as string,
      currentLocation: location
    });
    return finalRoute;
  }

  const calculateNotificationCardAnnotationAction = () => {
    if (!notification?.data?.body?.patientId) {
      console.error("No patient ID provided...", notification);
    }
    const finalRoute = calculateActionRoutes({
      action: AVAILABLE_ACTIONS.WORKPLAN_ANNOTATIONS,
      patientId: notification?.data?.body?.patientId?.toString() as string,
      currentLocation: location
    });
    return finalRoute;
  }

  const calculatePatientEventAction = () => {
    if (!notification?.data?.body?.patientId) {
      console.error("No patient ID provided...", notification);
    }
    if (eventType === EventType.NOTIFICATION_TYPE_PATIENT_RELEASED) {
      const serviceId = parseInt(notification?.data?.body?.planId?.toString() || "INVALID_ID");
      if (serviceId && !isNaN(serviceId)) {
        return calculateActionRoutes({
          action: AVAILABLE_ACTIONS.ATTENTION_DETAILS,
          patientId: notification?.data?.body?.patientId?.toString() as string,
          currentLocation: location,
          serviceId
        });
      }
    }
  }

  if (notification.notificationType === NotificationType.call) {
    return ""
  }

  if (notification.notificationType === NotificationType.report) {
    return calculateReportActions();
  }

  if (notification.notificationType === NotificationType.prescription) {
    return calculatePrescriptionAction();
  }

  if (notification.notificationType === NotificationType.recommendation) {
    return calculateNotificationCardActionRecommendation();
  }

  if (notification.notificationType === NotificationType.annotations || [
    EventType.NOTIFICATION_TYPE_NOTE_PATIENT,
    EventType.NOTIFICATION_TYPE_NOTE_DR_DAILY_EVOL
  ].includes(eventType || "")) {
    return calculateNotificationCardAnnotationAction();
  }

  if (notification.notificationType === NotificationType.patientEvents) {
    return calculatePatientEventAction()
  }

}