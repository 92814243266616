export interface Props {
  patientId: number
  userData: any
}

export enum WORKPLAN_TABS {
  WORK_PLAN = 1,
  DAILY_EVOLUTION = 2,
}

export enum WORKPLAN_TABS_SECTIONS {
  TREATMENT = 'treatment',
  MEDICAL_PERSONNEL = 'medical_personnel',
  DEVICES = 'devices',
  RECOMMENDATIONS = 'recommendations',
  ANNOTATIONS = 'annotations',
}

export const TAB_SECTION_MAPPING = {
  [WORKPLAN_TABS.WORK_PLAN]: [
    WORKPLAN_TABS_SECTIONS.TREATMENT,
    WORKPLAN_TABS_SECTIONS.MEDICAL_PERSONNEL,
    WORKPLAN_TABS_SECTIONS.DEVICES,
    WORKPLAN_TABS_SECTIONS.RECOMMENDATIONS,
  ],
  [WORKPLAN_TABS.DAILY_EVOLUTION]: [WORKPLAN_TABS_SECTIONS.ANNOTATIONS],
}
