import FileIcon from 'assets/icons/icon_file.svg';
import LabelForm from 'components/LabelForm';

import { RecommendationModel } from '../hooks/useRecomendation';
import { PlanTodayRecommendationsModel } from '../hooks/userPlanToday';
import { formatDate } from 'features/shared/utils/text/formatDate';
import { isDate } from 'moment';
import WorkplanRecommendationsPatientCard from 'components/WorkplanRecommendationsPatientCard';

interface RecommendationsModel {
  data: PlanTodayRecommendationsModel[]
}

const Recommendations: React.FC<RecommendationsModel> = ({
  data
}) => {

  return (
    <>
      <div className='bg-white shadow-md border-solid border-[1px] border-grey-200 rounded-[16px] p-[16px] row-span-1 col-span-full'>
        <div className="flex justify-between items-center mb-4">
          <LabelForm
            icon={FileIcon}
            label="Recomendaciones del Médico"
            className='text-[14px] leading-[20px] font-medium'
          />
        </div>
        <div className=" rounded-lg  w-full sm:h-[476px] overflow-y-scroll scrollbar">
          {
            !data?.length ? (
              <div>El paciente no tiene recomendaciones</div>
            ) : null
          }
          {
            data?.length > 0 ? (
              data.map((el: RecommendationModel, index: number) => {
                const createdAt = el.createdAt;
                let label = "";
                if (createdAt && isDate(new Date(createdAt))) {
                  label = `Recomendación (${formatDate(createdAt, "DD/MM/YYYY")}, ${formatDate(createdAt, "hh:mm:ss")})`
                } else {
                  label = `Recomendación`;
                }
                return <WorkplanRecommendationsPatientCard
                  key={el.id}
                  label={label}
                  description={el.message}
                  className={index == 0 ? 'bg-[#EAF6FF]' : 'bg-[#F6F6F6]'}
                />
              })
            ) : (
              <></>
            )
          }
        </div>
      </div>
    </>
  )
}

export default Recommendations;
