import { FC } from 'react'

import {
  NotificationsCounterProps,
  HeaderNotificationsCounterProps,
} from './types'
import { variants } from './NotificationsCounterConst'

export const HeaderNotificationsCounter: FC<
  HeaderNotificationsCounterProps
> = ({ count }) => {
  return (
    <div
      className={`absolute top-0 right-0 flex items-center justify-center w-4 h-4 text-xs rounded-full text-white text-caption bg-[#D34000] select-none cursor-pointer`}
    >
      {count}
    </div>
  )
}

export const NotificationsCounter: FC<NotificationsCounterProps> = ({
  variant,
  count,
  onClick,
  selected
}) => {
  const selectedVariant = variants[variant]
  const selectedStyle = selected ? selectedVariant.active : selectedVariant.inactive;
  const includeCount = (variant as any) !== 'attended';
  return (
    <div
      className={`flex flex-row flex-wrap grow-1 shrink-1 py-3 px-4 rounded-lg justify-between items-center basis-[230px] ${selectedStyle.bg} cursor-pointer selected-variant-${variant}`}
      onClick={() => onClick(variant)}
      role='button'
    >
      <div className={`flex flex-row gap-2 items-center`}>
        <img
          src={selectedStyle.iconName}
          alt={selectedStyle.iconName}
          className="sm:w-[24px] sm:h-[24px]"
        />
        <span className={`text-caption font-normal ${(selectedStyle as any).labelClass || ""}`}>
          {selectedStyle.label}
        </span>
      </div>
      {
        includeCount ? <div
          className={`flex items-center justify-center w-7 h-7 text-xs rounded-full ${selectedStyle?.counter?.textCircleStyle}`}
        >
          {count}
        </div> : null
      }
    </div>
  )
}

