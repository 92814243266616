import { ISearchResult } from "features/shared/types/userType"
import { useHistory } from "react-router-dom"
import { HospitalizationDaysSnippet } from "./TableBody.snippets";
import { HEADER_ELEMENT_KEYS } from "../index.utils";
import * as Snippets from "./TableBody.snippets";
import { IIncomingFields } from "./Table.utils";
import { AVAILABLE_ACTIONS } from "global/constants/enums";
import { calculateHospitalizationDays } from "./TableBody.utils";

export const TableBodyElement = ({
  listResults,
  potentialActions,
  allowedFields
}: {
  listResults: ISearchResult<IIncomingFields>,
  potentialActions: AVAILABLE_ACTIONS[],
  allowedFields: HEADER_ELEMENT_KEYS[]
}) => {
  const history = useHistory()

  return (
    <tbody>{
      listResults?.data?.map((item: IIncomingFields, index: number) => (
        <tr
          key={index}
          className="h-[54px] hover:bg-grey-50 border-b border-[#C9C9C9]"
        >
          {allowedFields.map(element => {
            const id = item.patientId || item.id || 0;
            switch (element) {
              case HEADER_ELEMENT_KEYS.DOCUMENT_NUMBER:
                return id ? <Snippets.DocumentNumberSnippet key={element} documentNumber={item?.document} /> : <td />
              case HEADER_ELEMENT_KEYS.FULL_NAME:
                return id ? <Snippets.PatientFullNameSnippet key={element} name={item.name} lastName={item.lastname} /> : <td />
              case HEADER_ELEMENT_KEYS.PHONE:
                return id ? <Snippets.PatientPhoneSnippet key={element} phone={item.phone} /> : <td />
              case HEADER_ELEMENT_KEYS.ADDRESS:
                return (item?.address && id) ? <Snippets.PatientAddressSnippet key={element} address={item?.address} /> : <td />
              case HEADER_ELEMENT_KEYS.LOCATION:
                return (item.lat && item.lng) ? <Snippets.ViewLocationSnippet key={element} lat={item.lat} lng={item.lng} /> : <td />
              case HEADER_ELEMENT_KEYS.ADMISSION_DATE:
                return (id) ? <Snippets.PatientAdmissionDateSnippet key={element} admissionDate={item.admissionDate} /> : <td />
              case HEADER_ELEMENT_KEYS.NHC:
                return <Snippets.PatientClinicHistoryNumberSnippet key={element} nhc={item.nhc} />
              case HEADER_ELEMENT_KEYS.REGION:
                return <Snippets.RegionNameSnippet key={element} region={item.region || ""} />
              case HEADER_ELEMENT_KEYS.ATTENTION_DATE:
                return (id) ? <Snippets.AttentionDateSnippet key={element} patientId={id} history={history} attentionDate={item.admissionDate} /> : <td />
              case HEADER_ELEMENT_KEYS.RELEASE_DATE:
                return (id) ? <Snippets.AttentionDateSnippet key={element} patientId={id} history={history} attentionDate={item.plannedReleaseDate || item.releaseDate || ""} /> : <td />
              case HEADER_ELEMENT_KEYS.DIAGNOSIS:
                return (id) ? <Snippets.DiagnosisSnippet key={element} diagnosticText={item.diagnosis || ""} /> : <td />
              case HEADER_ELEMENT_KEYS.ACTIONS:
                return (id) ? <Snippets.ActionsSnippet key={element} serviceId={item.serviceId} patientId={id} history={history} potentialActions={potentialActions} /> : <td />
              case HEADER_ELEMENT_KEYS.HOSPITALIZATION_DAYS:
                return (id) ? <HospitalizationDaysSnippet key={element} totalDays={calculateHospitalizationDays(item)} totalExpectedDays={item.hospitalizationDaysDiffRelease || ""} /> : <td />
              case HEADER_ELEMENT_KEYS.ATTENTION_NUMBER:
                return (id) ? <Snippets.AttentionNumberSnippet key={element} attentionNumber={`${item.serviceId || 0}`} /> : <td />
              case HEADER_ELEMENT_KEYS.ATTENTION_STATUS:
                return (id) ? <Snippets.AttentionStatusSnippet key={element} attentionStatus={item.serviceStatus || ""} /> : <td />
              case HEADER_ELEMENT_KEYS.EVALUATING_DOCTOR_FULL_NAME:
                return (id) ? <Snippets.EvaluatorDoctorFullNameSnippet evaluatorDoctorFullName={item.evaluatorDoctorFullName || ""} key={element} /> : <td />
              default:
                return <td />
            }
          })}
        </tr>
      ))}
    </tbody>
  )
}