import React from 'react'

import useUser from 'features/shared/hooks/useUser'
import { Route, Switch, useHistory, useRouteMatch, useParams } from 'react-router-dom'
import PatientWorkPlan from './PatientWorkPlan';
import Breadcrumbs from 'components/Breadcrumbs';
import Loader from 'components/Loader';

const PatientDashboard: React.FC = () => {
  const history = useHistory();
  const { userCurrent } = useUser();
  let { path } = useRouteMatch();
  
  return (
    <div>
      <Breadcrumbs
        name="Mi plan de trabajo"
        currentPathName="Mi plan de trabajo"
        onClickPrev={() => { }}
        onClickHome={() => { history.push(`/`) }}
        />

        {!userCurrent?.id && <Loader />}
      <div className="w-full md:bg-white md:py-6 md:px-4 rounded-[16px] md:shadow-md mt-6">
        {
          <Switch>
            <Route exact path={path}>
              <PatientWorkPlan
                patientId={userCurrent?.id}
                userData={userCurrent}
              />
            </Route>
          </Switch>
        }
      </div>
    </div>
  )

}

export default PatientDashboard
