import { ChangeEvent, forwardRef, KeyboardEvent, useEffect, useState } from 'react';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  typeValidation?: string | "text" | "number" | "email";
  label?: string;
  errorText?: string;
  helperText?: string;
  value?: any;
  icon?: any;
  required?: boolean;
  send?: boolean;
  sendValidate?: boolean;
  onClickIcon?: () => void;
  classNameWrapper?: string
  fixCss?: boolean
  validateFieldOnChange?: boolean
}

const Input = forwardRef<HTMLInputElement, InputProps>(({
  typeValidation,
  className,
  classNameWrapper,
  label,
  errorText,
  helperText,
  value = '',
  icon,
  onClickIcon,
  send,
  sendValidate,
  required,
  fixCss,
  validateFieldOnChange = false,
  ...props
}, ref) => {

  const [hasValue, setHasValue] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    onHasValue(value);
  }, [value])


  const validateField = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (typeValidation) {
      case "text":
        const regexText = /^[A-Za-zÀ-ÖØ-öø-ÿ _]*$/;
        if (!regexText.test(e.key)) e.preventDefault();
        break;
      case "number":
        const regexNumber = /^\d*\.?\d*$/;
        if (!regexNumber.test(e.key)) e.preventDefault();
        break;
      case "phoneNumber":
        const regexPhoneNumber = /^[0-9\.\-\+]$/;
        if (!regexPhoneNumber.test(e.key)) e.preventDefault();
        break;
      case "document":
        const regexDocument = /^[0-9]$/;
        if (!regexDocument.test(e.key)) e.preventDefault();
        break;
      default:
        break;
    }
  }

  const validateChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    switch (typeValidation) {
      case "text":
        const regexText = /^[A-Za-zÀ-ÖØ-öø-ÿ _]*$/;
        return regexText.test(e.target.value)
      case "number":
        const regexNumber = /^\d*\.?\d*$/;
        return regexNumber.test(e.target.value)
      case "phoneNumber":
        const regexPhoneNumber = /^[0-9\.\-\+]$/;
        return regexPhoneNumber.test(e.target.value)
      case "document":
        const regexDocument = /^[0-9]$/;
        return regexDocument.test(e.target.value)
      default:
        break;
    }
  }

  const validateValue = (value: string) => {
    switch (typeValidation) {
      case "email":
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setIsValid(regex.test(value));
        break;
      case "number":
        const regexNumber = /^\d*\.?\d*$/;
        setIsValid(regexNumber.test(value));
        break;
      case "text":
        const regexText = /^[A-Za-zÀ-ÖØ-öø-ÿ _]*$/;
        setIsValid(regexText.test(value));
        break;
      default:
        setIsValid(value.toString().length > 0);
        break;
    }
  }

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    if (validateFieldOnChange && !validateChangeField(e)) {
      return
    }

    if (required) {
      validateValue(e.target.value);
    }

    onHasValue(e.target.value);

    if (props.onChange) props?.onChange(e);
  }

  const onHasValue = (val: any) => {
    val ? setHasValue(val.toString().length > 0) : setHasValue(false);
  }

  return (
    <div className={classNameWrapper}>
      <div
        className='
          w-full h-[56px] text-body1 relative
          flex items-center justify-start
        '
      >
        <input
          className={`
            peer
            px-[16px] w-full h-full rounded-[16px] bg-grey-50 text-grey-600 pt-4 outline-none
            hover:px-[calc(16px-1px)] hover:border-solid hover:border-[1px] hover:border-grey-400
            focus:px-[calc(16px-1px)] focus:border-solid focus:border-[1px] focus:border-[#003C73]
            ${icon !== undefined ? '!pr-[48px]' : ''}
            ${isValid ? '' : 'px-[calc(16px-1px)] border-solid border-[1px] !border-error-main'}
            ${className}
          `}
          onKeyPress={validateField}
          ref={ref}
          {...props}
          value={value}
          autoComplete="none"
          onChange={onChangeField}
        />
        <label
          className={`
            px-[16px] absolute text-gray-600 pointer-events-none ${fixCss && 'top-0'}
            ${hasValue ? `mb-6 text-caption text-neutral-600 ${fixCss && 'top-0'}` : ''}
            peer-focus:text-caption peer-focus:mb-6
            ${isValid ? '' : 'text-error-main'}
          `}
        >{label}
          {required ? (<span className="font-semibold">*</span>) : ''}
        </label>
        <div
          data-testid="iconInputClick"
          onClick={onClickIcon}
          className={`absolute right-[16px] pointer-events-none ${send && (sendValidate ? 'flex bg-primary-main  rounded-[8px] ' : 'bg-[#E2E2E2]  rounded-[8px] ')} `}>
          <img
            alt='icono'
            src={icon}
            className={`
            ${send !== undefined ? 'min-w-[40px] min-h-[40px] w-[40px] h-[40px] p-[10px] cursor-pointer' : 'w-[24px] h-[24px]'}
            ${icon !== undefined ? '' : 'hidden'}
            ${onClickIcon !== undefined ? 'cursor-pointer pointer-events-auto' : ''}
          `}
          />
        </div>
      </div>
      {
        isValid ? (
          <p className='
            px-[16px] text-caption p-0 pt-1 text-gray-600
          '>
            {helperText}
          </p>
        ) : (
          <p className='
            px-[16px] text-caption p-0 pt-1 text-error-main
          '>
            {hasValue && required ? 'Este campo es obligatorio' : errorText}
          </p>
        )
      }
    </div>
  )
});

export default Input
